import React, { Fragment } from 'react';
import moment from 'moment';


const getHourlyCounterHeader = (props) => props.plan_days.map((d, i) => {
	const day = moment(d.day).format("dd");
	const dayNum = moment(d.day).format("DD");

	const details = d.details;

	// console.log(d);
	let day_type;

	// TODO adaptar multigrup
	if (details.length > 0) {
		if (details[0].special_day)
			day_type = details[0].special_day.day_type;

		if (details[0].weekday)
			day_type = details[0].weekday.day_type;
	}

	const color = day_type.color;

	return (
		<Fragment key={i}>
			<th
				className="th-day head-hours-main"
				style={{ color: color }}
			>
				<div className="th-wrap">
					<span className="span-day">{day}</span>
					<span className="span-num">{dayNum}</span>
				</div>
			</th>
			{getPlanHourlyRows(props)}
		</Fragment>
	)
});

// old {h}-{h + 1}
const getPlanHourlyRows = (props) => props.working_hours_list.map((h, i) => {
	return (
		<th className="head-hours" key={i}>
			{h}h
		</th>
	)
});

const getHourlyCounterRow = (props) => props.plan_days.map((d, i) => {
	var day_totals = [];
	props.plan_assignments.map((assignment, i) => {
		const day_filter = assignment.plan_assignments_hourly_totals.filter(t => t.day === d.day);
		if (day_filter.length > 0) day_totals.push(...day_filter[0].hours);
		return true;
	});
	return (
		<Fragment key={i}>
			<td
				className="td-day"
			>
			</td>
			{getHourlyCounterRowContent(props, day_totals)}
		</Fragment>
	)
});

const getHourlyCounterRowContent = (props, day_totals) => props.working_hours_list.map((h, i) => {
	const hour_filter = day_totals.filter(d => d.hour === h);
	let suma = hour_filter.reduce((acc, numero) => acc + numero.count, 0);
	suma = parseFloat(suma).toFixed(2).toString().replace(".", ",").replace(",00", "");

	return (
		<td key={i} className="td-hour shift-cell">
			{suma}
		</td>
	)
});


const PlanHourlyCounter = (props) => {
	// console.log(props);
	return (
		<table className="table table-bordered table-plan table-hourly-counter">
			<thead>
				<tr>
					<th><div className='th-wrap'></div></th>
					{getHourlyCounterHeader(props)}
				</tr>
			</thead>
			<tbody>
				<tr>
					<th className='main-col'>
						<div className='th-wrap'>
							Total horas
						</div>
					</th>
					{getHourlyCounterRow(props)}
				</tr>
			</tbody>
		</table>
	)
}

export default PlanHourlyCounter