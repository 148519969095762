import React from "react";
import { connect } from 'react-redux';
import { FormGroup, FormControl, FormLabel, Col, Row, InputGroup } from "react-bootstrap";
import { FiCalendar } from "react-icons/fi";
import moment from "moment";
import Layout from "components/Layout";
import SubmitButton from "components/SubmitButton";
import { iPlanService, appService } from "services";
import { errorFormating } from "utils/utils";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es"; // datepicker locale
registerLocale("es", es);

import * as groupsActions from 'actions/groupsActions';
import { showModal, hideModal, updateModalLoading } from "actions/modalActions";
import Modal from 'components/Modal';

class Forecasting extends React.Component {
	constructor(props) {
		super(props);

		//console.log(props);
		this.state = {
			isLoading1: false,
			isLoading2: false,
			hasError1: false,
			hasError2: false,
			submitted1: false,
			submitted2: false,
			errors: {},
			selectedFile1: null,
			selectedFile2: null,
			start: "",
			end: "",
			groupId: -1,
			staffingLevelId: -1,
			value: "",
			completado: false,
		};
		this.closeModal = this.closeModal.bind(this);

	}

	componentDidMount() {
		const {
			groups,
			getAllGroups,
			staffing_levels,
			getStaffingLevels,
		} = this.props;

		if (!groups || groups.length === 0) {
			getAllGroups();
		}

		if (!staffing_levels || staffing_levels.length === 0) {
			getStaffingLevels();
		}
	}

	closeModal() {
		this.props.hideModal()
	}

	handleSwitch = (event) => {
		this.setState({
			[event.target.id]: event.target.checked,
		});
	};
	handleDateChange = (id, date) => {
		let key = id;
		let value = date ? moment(date).format("YYYY-MM-DD") : null;

		this.setState({
			[key]: value,
		});
	};

	handleChange = (event) => {
		const { id, value, files } = event.target;

		// console.log(event);
		// console.log(id);

		this.setState((prevState) => ({
			...prevState,
			[id]: files && files.length > 0 ? files[0] : value,
		}));
	};

	checkValidField = (name, submittedKey) => {
		if (!this.state[submittedKey]) return false; // Si no se ha enviado el formulario, no validamos.

		const value = this.state[name];

		return (
			value === undefined ||
			value === "" ||
			value === null ||
			value === -1 ||
			(name === "value" && (value === "0" || value === 0))
		);
	};

	openForecastDownloadModal(token) {
		this.props.showModal(
			{
				open: true,
				title: "Calcular el forecast de ventas",
				style: { width: "400px" },
				confirmText: "Descargar",
				loadingText: "Descargando...",
				message:
					"El forecast de ventas se ha calculado correctamente. Haz click en Descargar para iniciar la descarga",
				closeModal: this.closeModal,
				confirmAction: () => {
					this.props.updateModalLoading(true);
					this.downloadExport(token, "forecast_ventas.xlsx")
				},
			},
			"confirm"
		);
	}

	async downloadExport(token, filename) {
		const response = await appService.getExported(token);
		var url = window.URL.createObjectURL(response);
		var a = document.createElement("a");
		document.body.appendChild(a);
		a.href = url;
		a.download = filename;
		a.click();
		this.props.updateModalLoading(false); // Cambiar a no cargando
		this.closeModal(); // cerrar modal de confirmación
	}

	handleForecasting = async (event) => {
		const { start, end, selectedFile1 } = this.state;
		event.preventDefault();
		this.setState({
			isLoading1: true,
			hasError1: false,
			hasError2: false,
		});

		if (selectedFile1) {
			var formData = new FormData();
			formData.append("filename", selectedFile1);
			formData.append("start", start);   // Fecha de inicio
			formData.append("end", end);       // Fecha de fin
			// console.log(formData);
			const response = await iPlanService.salesForecast(formData);

			console.log(response);

			if (response.ok && !response.has_errors) {
				this.setState(
					{
						isLoading1: false,
						hasError1: false,
						errors: {},
					},
					() => {
						if (response.access_token) {
							this.openForecastDownloadModal(response.access_token);
						}
					}
				);
			} else if (!response.ok) {
				this.setState({
					isLoading1: false,
					hasError1: true,
					submitted1: true,
					errors: {
						message: "Se ha producido un error",
					},
				});
			}
			else {
				this.setState({
					isLoading1: false,
					hasError1: true,
					submitted1: true,
					errors: response.rows.length > 0 ? {} : response.errors[0],
				});
			}
		} else {
			this.setState({
				isLoading1: false,
				submitted1: true,
			});
		}
	};

	handleForecastingNeeds = async (event) => {
		event.preventDefault();
		const { value, groupId, staffingLevelId, selectedFile2 } = this.state;

		this.setState({
			isLoading2: true,
			hasError1: false,
			hasError2: false,
		});

		if (selectedFile2) {
			var formData = new FormData();
			formData.append("filename", selectedFile2);
			formData.append("value", value);
			formData.append("groupId", groupId);
			formData.append("staffingLevelId", staffingLevelId);
			const response = await iPlanService.staffingLevelsForecast(formData);

			console.log(response);

			if (response.ok && !response.has_errors) {
				this.setState(
					{
						isLoading2: false,
						hasError2: false,
						errors: {},
						completado: true,
					},
					() => {
						// TODO hacer algo
					}
				);
			} else if (!response.ok) {
				this.setState({
					isLoading2: false,
					hasError2: true,
					submitted2: true,
					errors: {
						message: "Se ha producido un error",
					},
				});
			}
			else {
				this.setState({
					isLoading2: false,
					hasError2: true,
					submitted2: true,
					errors: response.rows.length > 0 ? {} : response.errors[0],
				});
			}
		} else {
			this.setState({
				isLoading2: false,
				submitted2: true,
			});
		}
	};

	getForecastingContent = () => {
		const { start, end } = this.state;

		return (
			<form onSubmit={this.handleForecasting}>
				<FormGroup
					controlId="selectedFile1"
				>
					Selecciona el excel con tus registro de ventas diarias
					<FormControl
						type="file"
						onChange={this.handleChange}
						className="form-control"
						isInvalid={this.checkValidField("selectedFile1", "submitted1")}
					/>
					<FormControl.Feedback type="invalid">
						Selecciona el archivo con las ventas
					</FormControl.Feedback>
				</FormGroup>

				<Row className="row-fechas">
					<Col sm={6}>
						<FormGroup
							controlId="start"
							className={`form-date ${start === "" && "empty"}  ${this.checkValidField('start', "submitted1") && "is-invalid"}`}
						>
							<FormLabel>Inicio <span className="label-required">*</span></FormLabel>
							<DatePicker
								selected={(start === "" || !start) ? null : moment(start).toDate()}
								onChange={(date) => this.handleDateChange("start", date)}
								isInvalid={this.checkValidField("start", "submitted1")}
								className="form-control"
								dateFormat="dd/MM/yyyy"
								locale='es'
								placeholderText="dd/mm/aaaa"
							/>
							<FiCalendar />
							<FormControl.Feedback type="invalid">
								Tienes que indicar la fecha de inicio
							</FormControl.Feedback>
						</FormGroup>
					</Col>

					<Col sm={6}>
						<FormGroup
							controlId="end"
							className={`form-date ${end === "" && "empty"}  ${this.checkValidField('end', "submitted1") && "is-invalid"}`}
						>
							<FormLabel>Fin <span className="label-required">*</span></FormLabel>
							<DatePicker
								selected={(end === "" || !end) ? end : moment(end).toDate()}
								onChange={(date) => this.handleDateChange("end", date)}
								isInvalid={this.checkValidField("end", "submitted1")}
								className="form-control"
								dateFormat="dd/MM/yyyy"
								locale='es'
								placeholderText="dd/mm/aaaa"
							/>
							<FiCalendar />
							<FormControl.Feedback type="invalid">
								Tienes que indicar la fecha de fin
							</FormControl.Feedback>
						</FormGroup>
					</Col>
				</Row>

				<SubmitButton
					type="submit"
					isLoading={this.state.isLoading1}
					text="Calcular forecast"
					loadingText="Calculando..."
				/>
			</form>
		);
	};

	getNeedsContent = () => {
		const { value, staffingLevelId, groupId, completado } = this.state;
		const { groups, staffing_levels } = this.props;
		const optionGroupList = groups.map((item, i) => (
			<option key={i} value={item.id}>{item.name}</option>
		));
		const optionStaffingLevelsList = staffing_levels.map((item, i) => (
			<option key={i} value={item.id}>{item.name}</option>
		));

		return (
			<form onSubmit={this.handleForecastingNeeds}>
				<FormGroup
					controlId="selectedFile2"
				>
					Selecciona el excel con el forecast de ventas
					<FormControl
						type="file"
						onChange={this.handleChange}
						className="form-control"
						isInvalid={this.checkValidField("selectedFile2", "submitted2")}
					/>
					<FormControl.Feedback type="invalid">
						Selecciona el archivo con el forecast de ventas
					</FormControl.Feedback>
				</FormGroup>
				<Row className="row-fechas">
					<Col sm={6} md={4}>
						<FormGroup controlId="value">
							<FormLabel>Base operativa <span className="label-required">*</span></FormLabel>
							<InputGroup hasValidation>
								<FormControl
									type="text"
									value={value.toString().replace(".", ",")}
									onChange={this.handleChange}
									placeholder="0"
									isInvalid={this.checkValidField("value", "submitted2")}
								/>
								<InputGroup.Append>
									<InputGroup.Text>euros</InputGroup.Text>
								</InputGroup.Append>
								<FormControl.Feedback type="invalid">
									Selecciona un valor de referencia base (en euros)
								</FormControl.Feedback>
							</InputGroup>
						</FormGroup>
					</Col>
					<Col sm={6} md={4}>
						<FormGroup controlId="groupId">
							<FormLabel>Grupo <span className="label-required">*</span></FormLabel>
							<FormControl
								as="select"
								value={groupId}
								onChange={this.handleChange}
								isInvalid={this.checkValidField("groupId", "submitted2")}
							>
								<option value="-1">Seleccionar grupo</option>
								{optionGroupList}
							</FormControl>
							<FormControl.Feedback type="invalid">
								Selecciona un grupo
							</FormControl.Feedback>
						</FormGroup>
					</Col>
					<Col sm={6} md={4}>
						<FormGroup controlId="staffingLevelId">
							<FormLabel>Necesidades de personal <span className="label-required">*</span></FormLabel>
							<FormControl
								onChange={this.handleChange}
								value={staffingLevelId}
								isInvalid={this.checkValidField("staffingLevelId", "submitted2")}
								as="select"
							>
								<option value="-1">Seleccionar necesidades de personal</option>
								{optionStaffingLevelsList}
							</FormControl>

							<FormControl.Feedback type="invalid">
								Selecciona una necesidad de personal
							</FormControl.Feedback>
						</FormGroup>
					</Col>
				</Row>
				<SubmitButton
					type="submit"
					isLoading={this.state.isLoading2}
					text="Actualizar necesidades de personal"
					loadingText="Actualizando..."
				/>
				{completado && <div className="alert alert-success mt10">
					<p className="alert-intro">
						Las necesidades de personal se han actualizado correctamente.</p>
				</div>}
			</form>
		);
	};

	getErrorsContent = () => {
		const { errors } = this.state;

		return (
			errors.message && <p className="error-message">{errors.message}</p>
		);
	};

	render() {

		const { hasError1, hasError2 } = this.state;

		return (
			<>
				<Layout className="page-imports">
					<div className="container">
						<div className="heading">
							<div className="heading-left">
								<h1 className="title">Forecasting</h1>
							</div>
						</div>

						<h2 className="subtitle">Calcular el forecast de ventas</h2>
						<div className="alert alert-info">
							<p className="mb5">Introduce un excel con las ventas reales pasadas. Este excel debe tener dos columnas:</p>
							<ul className="lista">
								<li>Día</li>
								<li>Ventas de ese día (en euros)</li>
							</ul>
						</div>

						{this.getForecastingContent()}

						{hasError1 && this.getErrorsContent()}

						<hr className="mb30" />

						<h2 className="subtitle">Actualizar las necesidades de personal en base al forecast</h2>

						<p>Revisa el excel con el forecast y, si es correcto, rellena el siguiente formulario para calcular las necesidades operativas</p>

						{this.getNeedsContent()}

						{hasError2 && this.getErrorsContent()}

					</div>
				</Layout>
				<Modal hideModal={this.props.hideModal} />
			</>
		);
	}
}

const mapStateToProps = (reducers) => ({
	...reducers.groupsReducer,
	loading: reducers.plansReducer.loading,
});

const mapDispatchToProps = (dispatch) => ({
	hideModal: () => dispatch(hideModal()),
	showModal: (modalProps, modalType) => {
		dispatch(showModal({ modalProps, modalType }))
	},
	updateModalLoading: (isLoading) => dispatch(updateModalLoading(isLoading)),
	getAllGroups: () => dispatch(groupsActions.getAllGroups()),
	getStaffingLevels: (q) => dispatch(groupsActions.getAllStaffingLevels(q)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Forecasting);