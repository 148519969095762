import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FormGroup, FormControl, FormLabel, Dropdown, OverlayTrigger, Tooltip, Popover, ButtonGroup, Button } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import { isSafari } from 'react-device-detect';
import Moment from "react-moment";
import moment from "moment";
import "moment-timezone";
import { FiClock, FiPlus, FiMoreHorizontal, FiUpload, FiAlertTriangle, FiSliders, FiList } from "react-icons/fi";
import { MdOutlineTimer } from "react-icons/md";
import { FaMapMarkerAlt, FaMobileAlt, FaDesktop, FaRegComment } from "react-icons/fa";
import { GoAlert } from "react-icons/go";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import { TabletOrDesktop, Mobile } from "utils/responsive";
import * as timesActions from "actions/timesActions";
import * as employeesActions from "actions/employeesActions";
import * as groupsActions from "actions/groupsActions";
import { showModal, hideModal, updateModalLoading } from "actions/modalActions";
import { timesService, appService, accountsService, plansService, transactionsService } from "services";
import { getDiffTime, formatSecondsToTime, formatMinutesToTime, getWorkedTimeRaw, getBalanceHours } from "utils/datetime";
import Layout from "components/Layout";
import Loading from "components/Loading";
import Modal from "components/Modal";
import Switch from "components/Switch";
import TimeObservationEdit from "components/TimeRegistrations/TimeObservationEditModal";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import { isAdmin, isSupervisor } from "utils/utils";
import { TIME_ALERTS } from "components/Times/utils";
import { TIME_LIST_VIEWS } from "components/Times/utils";
import { convertFromUTC } from 'utils/datetime';

class Times extends Component {
	constructor(props) {
		super(props);
		// console.log(props);

		const today = new Date();
		var storedStartDate = localStorage.getItem("times_startDate");
		var storedEndDate = localStorage.getItem("times_endDate");
		var storedSearchEmployee = localStorage.getItem("times_searchEmployee");
		var storedFilterGroupId = localStorage.getItem("times_filterGroupId");
		var startDate = storedStartDate ? storedStartDate : moment(today).startOf("month").format("YYYY-MM-DD");
		var endDate = storedEndDate ? storedEndDate : moment(today).endOf("month").format("YYYY-MM-DD");

		this.state = {
			loading: true,
			error: false,
			times: [],
			total_time: 0,
			total_working_time: 0,
			searchEmployee: storedSearchEmployee ? storedSearchEmployee : "",
			startDate,
			endDate,
			filterGroupId: storedFilterGroupId ? storedFilterGroupId : "",
			filter_approved: false,
			sort: "+employee,+day",
			focusedInput: null,
			page: 0, // result pagination
			hasMore: true, // time list has more results to fetch
			include_approved_workflows: false,
			showFilterOptions: false,
			filter_alertas: -1,
			is_list_view: false,
			time_list_view: TIME_LIST_VIEWS.employee_day,
			show_rest_time: false,
			objective_hours: [],
			planned_hours: [],
		};

		this.closeModal = this.closeModal.bind(this);
		this.openExportModal = this.openExportModal.bind(this);
		this.openAlertModal = this.openAlertModal.bind(this);
		this.openObservationsModal = this.openObservationsModal.bind(this);
	}

	componentDidMount() {
		this.props.getSelectableEmployees();
		this.getTimes();
		this.getGroups();
		this.getObjectiveHoursList();
	}

	async getGroups() {
		await this.props.getAllGroups();
	}

	// Employee view list / grid
	//list: 1,
	//employee_day: 2,
	//employee_week: 3,
	handleChangeView = (time_list_view) => {
		this.setState({
			is_list_view: time_list_view === 1,
			time_list_view
		});
	};

	fetchMoreData = () => {
		const { page } = this.state;
		this.setState(
			{
				page: page + 1,
			},
			() => {
				this.getTimes();
				this.getObjectiveHoursList();
			}
		);
	};

	async getTimeAlerts(time_list) {
		const { searchEmployee, startDate, endDate, filterGroupId, filter_approved, page, sort, not_ended } =
			this.state;

		const response_alerts = await timesService.alerts({
			employee_id: searchEmployee,
			start_from: startDate,
			start_to: endDate,
			group_id: filterGroupId,
			page,
			sort,
			not_ended,
			include_supervised: isSupervisor(),
			pending_only: filter_approved
		});
		let times_with_alerts = time_list.map((item) => {
			if (response_alerts.ok) {
				const alerts = response_alerts.filter((a) => a.time_registration_id === item.id);
				item.alerts = alerts ? alerts : [];
			} else {
				item.alerts = [];
			}
			return item;
		});
		return times_with_alerts;
	}

	async getTimes() {
		const { times, searchEmployee, startDate, endDate, filterGroupId, filter_approved, page, sort, not_ended, filter_alertas } =
			this.state;

		this.setState({
			loading: true,
		});
		const response = await timesService.getAll({
			employee_id: searchEmployee,
			start_from: startDate,
			start_to: endDate,
			group_id: filterGroupId,
			page,
			sort,
			not_ended,
			include_supervised: isSupervisor(),
			pending_only: filter_approved
		});

		// console.log(response);

		if (response.ok) {
			let new_times = response.data;

			if (new_times.length > 0)
				new_times = await this.getTimeAlerts(new_times);

			// console.log(new_times);

			if (page > 0) {
				new_times = times.concat(new_times);
			}

			// filter_alertas -1 todos los fichajes
			// filter_alertas 0 sin alertas
			// filter_alertas 1 con alertas
			if (parseInt(filter_alertas) === 0) {
				// console.log("sin alertas");
				new_times = new_times.filter((t) => t.alerts.length === 0);
			}
			if (filter_alertas > 0) {
				// console.log("con alertas");
				new_times = new_times.filter((t) => t.alerts && t.alerts.length > 0);
			}

			this.setState({
				times: new_times,
				total_time:
					response.aggregates && response.aggregates.overall_seconds
						? response.aggregates.overall_seconds
						: 0,
				total_working_time: response.aggregates && response.aggregates.working_seconds
					? response.aggregates.working_seconds
					: 0,
				loading: false,
				hasMore: response.data.length > 0,
			});
		} else {
			this.setState({
				error: true,
				loading: false,
			});
		}
	}

	openObservationsModal(time, titleObservations) {
		this.props.showModal(
			{
				open: true,
				title: titleObservations,
				style: { width: "400px" },
				content: (
					<TimeObservationEdit
						//getTimeList={this.getTimes} // no cal
						isReadonly={true}
						time={time}
						handleClose={this.closeModal}
					/>
				),
				closeModal: this.closeModal,
			},
			"edit"
		);
	}

	async handleExport() {
		const userId = this.state.searchEmployee;
		const { startDate, endDate, filterGroupId, include_approved_workflows } = this.state;
		// const include_supervised = isSupervisor();
		const token = await timesService.exportList({
			employee_id: userId,
			start_from: startDate,
			start_to: endDate,
			group_id: filterGroupId,
			include_approved_workflows,
			// include_supervised
		});

		if (token.ok) {
			this.openExportModal(token.access_token, "fichajes.xlsx");
		} else {
			this.openAlertModal(
				"Ha ocurrido un error en la exportación de tiempos",
				"Error al exportar"
			);
		}
	}

	async handleExportIncludeAllWorkflows() {
		const userId = this.state.searchEmployee;
		const { startDate, endDate, filterGroupId, include_approved_workflows } = this.state;
		// const include_supervised = isSupervisor();
		const token = await timesService.exportList({
			employee_id: userId,
			start_from: startDate,
			start_to: endDate,
			group_id: filterGroupId,
			include_approved_workflows,
			// include_supervised,
			include_all_workflows: true
		});

		if (token.ok) {
			this.openExportModal(token.access_token, "fichajes_ausencias.xlsx");
		} else {
			this.openAlertModal(
				"Ha ocurrido un error en la exportación de tiempos",
				"Error al exportar"
			);
		}
	}

	openExportModal(token, filename) {
		this.props.showModal(
			{
				open: true,
				title: "Exportar fichajes",
				style: { width: "400px" },
				confirmText: "Descargar",
				loadingText: "Descargando...",
				message:
					"Los tiempos seleccionados se han exportado correctamente. Haz click en Descargar para iniciar la descarga",
				closeModal: this.closeModal,
				confirmAction: () => {
					this.props.updateModalLoading(true);
					this.downloadExport(token, filename);
				},
			},
			"confirm"
		);
	}


	async downloadExport(token, filename) {
		//console.log(token);
		const response = await appService.getExported(token);
		// console.log(response);

		var url = window.URL.createObjectURL(response);
		var a = document.createElement("a");
		document.body.appendChild(a);
		a.href = url;
		a.download = filename;
		a.click();

		this.props.updateModalLoading(false); // Cambiar a no cargando
		this.closeModal(); // cerrar modal de confirmación
	}

	closeModal() {
		this.props.hideModal();
	}

	openAlertModal(message, title) {
		this.props.showModal(
			{
				open: true,
				title: "Error" || title,
				style: { width: "400px" },
				message: message,
				closeModal: this.closeModal,
			},
			"alert"
		);
	}

	handleChangeSearch = (event) => {
		const key = event.target.id;
		const value = event.target.value;

		localStorage.setItem("times_" + key, value);

		this.setState(
			{
				page: 0, // reset pagination
				[key]: event.target.value,
			},
			() => {
				this.getTimes();
				this.getObjectiveHoursList();
			}
		);
	};

	handleSwitch = (event) => {
		const targetId = event.target.id;
		this.setState(
			{
				page: 0, // reset pagination
				[targetId]: event.target.checked,
			},
			() => {
				if (targetId !== "show_rest_time") {
					this.getTimes();
				}
			}
		);
	};

	onDatesChange = (dates) => {
		var { startDate, endDate } = dates;

		if (startDate) startDate = startDate.format("YYYY-MM-DD");

		if (endDate) {
			endDate = endDate.format("YYYY-MM-DD");
		}

		localStorage.setItem("times_startDate", startDate);
		localStorage.setItem("times_endDate", endDate);

		this.setState(
			{
				page: 0, // reset pagination
				startDate,
				endDate,
			},
			() => {
				this.getTimes();
			}
		);
	};

	// switch approval state
	handleTimeApproval = (event, time) => {
		const { times } = this.state;
		const isApproved = event.target.checked;
		var approvalList = [];

		// envío de un solo fichaje para aprobación
		// const updatedTimes = times.map((item) => {
		// 	if (item.id === time.id) {
		// 		item.approved = isApproved;

		// 		let temp = { id: item.id, approved: isApproved };
		// 		approvalList.push(temp);
		// 	}
		// 	return item;
		// });

		// aprueba fichajes del día
		const start = time.day;
		const employee_id = time.employee.id;
		const updatedTimes = times.map((item) => {
			const date1 = new Date(item.day).getDate();
			const date2 = new Date(start).getDate();

			// aqui hay que añadir el employee porque pueden haber varios ese día
			if (date1 === date2 && (item.employee.id === employee_id) && ((item.start !== null && item.end !== null) || item.day_of_rest)) {
				item.approved = isApproved;

				let temp = { id: item.id, approved: isApproved };
				approvalList.push(temp);
			}
			return item;
		});

		this.handleApproval(updatedTimes, approvalList);
	};

	handlePeriodApproval = () => {
		const { times } = this.state;
		var approvalList = [];

		const updatedTimes = times.map((item) => {
			if (
				(item.start !== null && item.end !== null && item.end !== undefined)
			) {
				// console.log(item);
				if (!item.approved) {
					item.approved = true;

					let temp = { id: item.id, approved: true };
					approvalList.push(temp);
				}
			}
			return item;
		});

		this.handleApproval(updatedTimes, approvalList);
	};


	handleApproval = async (updatedTimes, approvalList) => {
		const response = await timesService.approve(approvalList);

		if (response.ok) {
			this.setState({
				times: updatedTimes,
			});
		}
		else {
			// show error
			let textError = response.errors[0].description;

			this.openAlertModal(
				textError,
				"Error al aprobar fichajes"
			);
			this.getTimes();
		}
	};

	handleSort(action) {
		const { sort, is_list_view } = this.state;
		// console.log(sort);
		// console.log(action);

		let newSort = sort;

		// Aplicar lógica especial solo en la vista específica
		if (is_list_view) {
			const defaultSortFields = ['employee', 'day'];
			defaultSortFields.forEach(field => {
				if (!sort.includes(field)) {
					newSort = `+${field},${newSort}`; // Añadir con orden ascendente por defecto si falta
				}
			});

			const sortParts = newSort.split(',').map(part => ({
				field: part.substring(1),
				direction: part.substring(0, 1),
			}));

			// Encuentra si el action ya está en el ordenamiento
			const actionIndex = sortParts.findIndex(part => part.field === action);

			if (actionIndex > -1) {
				// Cambiar el signo si el campo ya estaba
				sortParts[actionIndex].direction = sortParts[actionIndex].direction === '+' ? '-' : '+';
			} else {
				// Añadir el nuevo campo con signo positivo si no estaba
				sortParts.push({ field: action, direction: '+' });
			}

			// Reconstruir la cadena de ordenamiento
			newSort = sortParts.map(part => `${part.direction}${part.field}`).join(',');
		} else {
			// Lógica de ordenamiento estándar para otras vistas
			const newSorting = sort.substring(0, 1) === "+" ? "-" : "+";
			newSort = `${newSorting}${action}`;
		}

		// console.log(newSort);

		this.setState(
			{
				page: 0,
				sort: newSort,
			},
			() => {
				this.getTimes();
			}
		);
	}

	// handleSort(action) {
	// 	const { sort } = this.state;
	// 	if (sort !== action) {
	// 		const newSorting = sort.substring(0, 1) === "+" ? "-" : "+";
	// 		this.setState(
	// 			{
	// 				page: 0,
	// 				sort: newSorting + action,
	// 			},
	// 			() => {
	// 				this.getTimes();
	// 			}
	// 		);
	// 	}
	// }


	// get objective hours list by employee and month
	async getObjectiveHoursList() {
		var { searchEmployee, account_objetivo, startDate, endDate } = this.state;

		// console.log("getObjectiveHoursList");
		// console.log({ searchEmployee });
		this.setState({
			loading: true,
		});

		if (!account_objetivo) {
			account_objetivo = await accountsService.getAll("Horas objetivo"); // guarrofix
		}

		const account_objetivo_id = (account_objetivo.data.length > 0) ? account_objetivo.data[0].id : -1;

		// fetch horas objetivo
		const response_objective = await transactionsService.getAll(searchEmployee, account_objetivo_id, startDate, endDate, "", 0, "+day");
		const response_planned = await plansService.getPlannedHours({ employee_id: searchEmployee, start: startDate, end: endDate });
		// console.log(employee_id);
		// console.log(response_objective);
		// console.log(response_planned);

		this.setState(
			{
				loading: false,
				account_objetivo,
				objective_hours: response_objective.data,
				planned_hours: response_planned.ok ? response_planned : [],
			}
		);
	}

	getBalanceByDay = (day, list, employee_id) => {
		const filtered_hours = list.filter((d) => {
			let selected_day = moment.utc(day).local().format("D");
			let d_day = moment.utc(d.day).local().format("D");

			return selected_day === d_day && d.employee.id === employee_id;
		});

		// console.log(filtered_hours);
		const suma = filtered_hours.reduce((acc, numero) => acc + numero.value, 0);

		var return_value = 0;
		if (filtered_hours.length === 1)
			return_value = filtered_hours[0].value;
		else if (filtered_hours.length > 1)
			return_value = suma;


		const formattedValue = new Intl.NumberFormat("de-DE", { maximumSignificantDigits: 2 }).format(return_value);
		return formattedValue;
	};

	getSortableTh = (name, action, extraclass) => {
		const { sort } = this.state;

		let symbol = <FaSort className="icon-sort" />;
		if (sort.substring(1) === action) {
			symbol =
				sort.substring(0, 1) === "+" ? (
					<FaSortUp className="icon-sort icon-sort-up" />
				) : (
					<FaSortDown className="icon-sort icon-sort-down" />
				);
		}

		return (
			<th
				className={`sortable-item ${extraclass !== null ? extraclass : ""}`}
				onClick={() => this.handleSort(action)}
			>
				{name} {symbol}
			</th>
		);
	};

	getContent = () => {
		// console.log(this.state);
		const { loading, error, times, hasMore, is_list_view, show_rest_time } = this.state;

		if (loading) {
			return <Loading />;
		}

		if (error) {
			return "No se han podido cargar los fichajes";
		}
		return (
			<InfiniteScroll
				dataLength={times.length}
				next={this.fetchMoreData}
				endMessage={
					times.length > 0 && (
						<p className="no-more-results">No hay más registros</p>
					)
				}
				hasMore={hasMore}
				loader={<Loading />}
			>
				<Mobile>
					<table className="table table-times table-mobile">
						<tbody>
							{times.length === 0
								? this.getEmptyRow()
								: this.getRowsMobile()}
						</tbody>
					</table>
				</Mobile>

				<TabletOrDesktop>
					<table className={`${!is_list_view && show_rest_time ? "table-rested" : ""} table table-times table-zebra`}>
						<thead>
							<tr>
								{this.getSortableTh("Empleado", "employee")}
								{/* {this.getSortableTh("Código empleado", "code")} */}
								<th title="Código de empleado">Código</th>
								{this.getSortableTh("Día", "day")}
								<th>Inicio</th>
								<th>Fin</th>
								<th>Horas</th>
								{this.getSortableTh("Tipo", "type")}
								{this.getSortableTh("Creado", "created")}
								<th></th>
								{!is_list_view && <th>Total día</th>}
								{!is_list_view && <th>Balance</th>}
								{this.getSortableTh("Aprobado", "approved", "aright")}
							</tr>
						</thead>
						<tbody>
							{times.length === 0
								? this.getEmptyRow()
								: this.getRows()}
						</tbody>
					</table>
				</TabletOrDesktop>
			</InfiniteScroll>
		);
	};

	getEmptyRow = () => {
		const { is_list_view } = this.state;
		return (
			<>
				<Mobile>
					<tr>
						<td colSpan="4" className="no-result">
							Sin resultados
						</td>
					</tr>
				</Mobile>
				<TabletOrDesktop>
					<tr>
						<td className="no-result" colSpan={`${is_list_view ? "10" : "12"}`}>
							Sin resultados
						</td>
					</tr>
				</TabletOrDesktop>
			</>
		);
	};

	getTooltipAlert = (time) => {
		if (!time.alerts || time.alerts.length < 1)
			return ""

		return (
			<Tooltip className="tooltip-alerta">
				<ul>
					{this.getTooltipAlertContent(time.alerts)}
				</ul>
			</Tooltip>
		);
	};

	getTooltipAlertContent = (alerts) => alerts.map((alert, i) => {
		const plan_time = convertFromUTC(alert.plan_time);
		return (
			<li key={i}><GoAlert className="tooltip-icon icon-alert" /> {TIME_ALERTS[alert.type]}
				{alert.plan_time && (<>. Planeado:  {plan_time}</>)}
			</li>
		);
	});

	getPopoverLocation = (time, isStart) => {
		const ip = isStart ? time.start_ip : time.end_ip;
		const lat = isStart ? time.start_lat : time.end_lat;
		const lon = isStart ? time.start_lon : time.end_lon;
		const device = isStart ? time.start_origin : time.end_origin;
		const url = `https://www.google.com/maps?q=${lat},${lon}`;

		return (
			<Popover className="popover-location">
				<div className="popover-content">
					<ul>
						{ip && <li><strong>IP:</strong> {ip}</li>}
						{lat && <li><strong>LAT:</strong> {lat}</li>}
						{lon && <li><strong>LON:</strong> {lon}</li>}
						{device && <li><strong>Dispositivo:</strong> {device}</li>}
					</ul>

					{((isStart && time.start_lat && time.start_lon) || (!isStart && time.end_lat && time.end_lon)) &&
						(<a href={url} target="_blank">Ver en Google Maps</a>)
					}
				</div>
			</Popover>
		);
	};

	getObservations = (time) => {
		return (
			<OverlayTrigger
				placement="right"
				overlay={<Tooltip>{time.observations}</Tooltip>}
			>
				<FaRegComment
					className="icon-comment"
					onClick={() => this.openObservationsModal(time, "Ver observaciones")}
				/>
			</OverlayTrigger>
		);
	};

	getRows = () => this.state.times.map((time, i) => {
		const { is_list_view, show_rest_time, objective_hours, planned_hours } = this.state;
		var isMain = true;
		const start = new Date(time.start);
		const end = new Date(time.end);
		const minutes = getDiffTime(start.setSeconds(0, 0), end.setSeconds(0, 0));
		const totalTime = formatMinutesToTime(minutes);
		var disabled = time.approved;
		const formatted_start = moment.utc(time.start).local().format("HH:mm");
		const formatted_end = time.end ? moment.utc(time.end).local().format("HH:mm") : "--:--";
		var extraRow = null;
		const balance_calcularion = this.props.user.time_registration_balance_calculation;

		// console.log(time);

		if (i > 0 && !is_list_view) {
			const prev_date = new Date(this.state.times[i - 1].day).getDate();
			const now = new Date(time.day).getDate();
			const prev_employee = this.state.times[i - 1].employee.id;
			const current_employee = time.employee.id;

			if (prev_date === now && prev_employee === current_employee)
				isMain = false;


			var showRest = true;
			const prev_end = new Date(this.state.times[i - 1].end);
			const diff = getDiffTime(prev_end.setSeconds(0, 0), start.setSeconds(0, 0));
			const formatted_diff = formatMinutesToTime(diff);

			if (current_employee !== prev_employee || prev_date !== now) {
				showRest = false;
			}

			if (show_rest_time && showRest && diff > 0) {
				extraRow = (<tr className="tr-rest">
					<td colSpan="10" >Descanso {formatted_diff}</td>
				</tr>);
			}
		}

		const tooltip_alert = this.getTooltipAlert(time);
		const tooltip_switch = this.getToolTipSwitch(time);
		const popover_location_start = this.getPopoverLocation(time, true);
		const popover_location_end = this.getPopoverLocation(time, false);
		const hasStartLocation = time.start_lat || time.start_lon;
		const hasStartDevice = time.start_origin;
		const hasEndLocation = time.end_lat || time.end_lon;
		const hasEndDevice = time.end_origin;

		const startDeviceIcon = hasStartDevice && hasStartDevice.substring(0, 4) === "[PC]" ? (<FaDesktop className="icon" />) : <FaMobileAlt className="icon" />;
		const endDeviceIcon = hasEndDevice && hasEndDevice.substring(0, 4) === "[PC]" ? (<FaDesktop className="icon" />) : <FaMobileAlt className="icon" />;

		const startIcon = hasStartLocation ? (<FaMapMarkerAlt className="icon" />) : startDeviceIcon;
		const endIcon = hasEndLocation ? (<FaMapMarkerAlt className="icon" />) : endDeviceIcon;
		const popoverTrigger = isSafari ? 'click' : 'focus';

		var dayTotal = -1;
		var balance = 0;
		var balanceTitle = "";
		var tooltipInfo = "";
		var sTipoTiempo = "";

		if (isMain) {
			var total_raw = getWorkedTimeRaw({ times: this.state.times, date: time.start, unit: "seconds", employee_id: time.employee.id });
			dayTotal = formatSecondsToTime(total_raw, "hm");

			const objective_hours_day = this.getBalanceByDay(time.day, objective_hours, time.employee.id);
			const planned_hours_day = this.getBalanceByDay(time.day, planned_hours, time.employee.id);
			// console.log(objective_hours);
			// console.log(planned_hours);
			// console.log(objective_hours_day);
			// console.log(planned_hours_day);

			if (minutes > 0) {

				if (balance_calcularion === "TARGET_HOURS") {
					// horas objetivo
					balance = getBalanceHours(objective_hours_day, total_raw);
					sTipoTiempo = " horas objetivo";
				}
				else {
					// horas planificadas
					balance = getBalanceHours(planned_hours_day, total_raw);
					sTipoTiempo = " horas planificadas";
				}

				// console.log(balance.short_raw);

				if (balance.short_raw > 0.016)
					balanceTitle = <p className="tooltip-title">Tienes un exceso de jornada de <strong>{balance.formated}</strong> según las {sTipoTiempo}</p>

				if (balance.short_raw < 0)
					balanceTitle = <p className="tooltip-title">Te faltan <strong>{balance.formated}</strong> para finalizar la jornada según las {sTipoTiempo}</p>

				tooltipInfo = <Tooltip className="tooltip-balance">
					<p>Horas objetivo: <strong>{objective_hours_day}h</strong></p>
					<p>Horas planificadas: <strong>{planned_hours_day}h</strong></p>
					{balanceTitle}
				</Tooltip>;
			}
		}

		return (
			<>
				{extraRow}
				<tr key={time.id} className={`${isMain ? "main" : "repetida"} tr-body`}>
					<td>
						<div className="tag-name-container">
							<Link to={`/times/${time.employee.id}/employee`}>
								{time.employee.name} {time.employee.surname}
							</Link>
						</div>
					</td>
					<td>
						{time.employee.code}
					</td>
					<td>
						<div className="tag-dia-container">
							<span className="d-none d-lg-inline-block tag-dia-setmana">
								<Moment format="dddd">{time.start}</Moment>
							</span>
							<Moment format="D MMMM YYYY">{time.start}</Moment>
						</div>
					</td>
					<td>{hasStartLocation || hasStartDevice ?
						(<OverlayTrigger overlay={popover_location_start} trigger={popoverTrigger} delay={{ show: 250, hide: 300 }}>
							<button type="button" className="btn-transparent btn-time-location"><span>{formatted_start}</span> {startIcon}</button>
						</OverlayTrigger>)
						: formatted_start}</td>
					<td>
						{hasEndLocation || hasEndDevice ?
							(<OverlayTrigger overlay={popover_location_end} trigger={popoverTrigger} delay={{ show: 250, hide: 300 }}>
								<button type="button" className="btn-transparent btn-time-location"><span>{formatted_end}</span> {endIcon}</button>
							</OverlayTrigger>) : formatted_end}
					</td>
					<td>{totalTime}</td>
					<td>{time.type && time.type.name}</td>
					<td>
						<Moment format="DD/MM/YY | HH:mm">{time.created}</Moment>
					</td>
					<td className="acenter">
						{time.observations && this.getObservations(time)}

						{time.alerts.length > 0 &&
							<OverlayTrigger overlay={tooltip_alert}>
								<FiAlertTriangle className="icon-alert" />
							</OverlayTrigger>
						}
					</td>
					{!is_list_view && <td className="col-total"><strong><span className="total">{dayTotal !== -1 && minutes !== 0 ? dayTotal : ""}</span></strong></td>}
					{!is_list_view && <td className="col-balance">
						<OverlayTrigger
							overlay={tooltipInfo}
						>
							<span className={`${balance.short_raw >= 0 ? "color-green-dark" : ""}  ${balance.short_raw < 0 ? "color-red" : ""}`}>
								{balance.short}
							</span>
						</OverlayTrigger>
					</td>}
					<td className="aright">
						<OverlayTrigger placement="left" overlay={tooltip_switch}>
							<div className="switch-container-overlay">
								<Switch
									controlId={`approved_${time.id}`}
									isOn={disabled}
									handleToggle={(e) => this.handleTimeApproval(e, time)}
								/>
							</div>
						</OverlayTrigger>
					</td>
				</tr>
			</>
		);
	});

	getToolTipSwitch = (time) => {
		var text = time.approved ? "Desaprobar" : "Aprobar";
		text += " fichajes del día";

		return (
			<Tooltip className="tooltip-switch">
				{text}
			</Tooltip>
		);
	};

	getRowsMobile = () => this.state.times.map((time) => {
		const start = new Date(time.start);
		const end = new Date(time.end);
		const minutes = getDiffTime(start.setSeconds(0, 0), end.setSeconds(0, 0));
		const totalTime = formatMinutesToTime(minutes);
		var disabled = time.approved;

		const tooltip = this.getTooltipAlert(time);
		const tooltip_switch = this.getToolTipSwitch(time);

		return (
			<tr key={time.id}>
				<td>
					<Link to={`/times/${time.employee.id}/employee`}>
						{time.employee.name} {time.employee.surname}
					</Link>
					<br />
					<span className="d-none d-sm-inline-block tag-dia-setmana">
						<Moment format="dddd">{time.start}</Moment>
					</span>

					<Moment className="d-inline-block d-sm-none" format="DD/MM/YYYY">
						{time.start}
					</Moment>
					<Moment className="d-none d-sm-inline-block" format="D MMMM">
						{time.start}
					</Moment>
				</td>
				<td>
					<span>{moment.utc(time.start).local().format("HH:mm")}</span> <br />{" "}
					<span>
						{time.end
							? moment.utc(time.end).local().format("HH:mm")
							: "--:--"}
					</span>
				</td>
				<td>
					<strong>{totalTime}</strong>
					<br />
					{time.type && time.type.name}
				</td>
				<td>
					{time.observations && this.getObservations(time)}
				</td>
				<td>
					{time.alerts.length > 0 &&
						<OverlayTrigger overlay={tooltip}>
							<FiAlertTriangle className="icon-alert" />
						</OverlayTrigger>
					}
				</td>
				<td className="aright">
					<OverlayTrigger placement="left" overlay={tooltip_switch}>
						<div className="switch-container-overlay">
							<Switch
								controlId={`approved_${time.id}`}
								isOn={disabled}
								handleToggle={(e) => this.handleTimeApproval(e, time)}
							/>
						</div>
					</OverlayTrigger>
				</td>
			</tr>
		);
	});


	toggleFilterOptions = () => {
		this.setState({
			showFilterOptions: !this.state.showFilterOptions
		});
	}

	render() {
		const { not_ended, filter_approved, include_approved_workflows, showFilterOptions, filter_alertas, time_list_view, show_rest_time, is_list_view } = this.state;
		const { groups, select_employees } = this.props;


		const optionList = groups.length > 0 && groups.map((item, i) => {
			return (
				<option key={i} value={item.id}>
					{item.name}
				</option>
			);
		}, this);

		// console.log(select_employees);

		let employeeList =
			select_employees.length > 0 &&
			select_employees.map((item, i) => {
				return (
					<option key={i} value={item.id}>
						{item.name} {item.surname}
					</option>
				);
			}, this);

		const total = formatSecondsToTime(this.state.total_time, "hms");
		const total_working = formatSecondsToTime(this.state.total_working_time, "hms");

		return (
			<>
				<Layout className="page-times" context="times">
					<div className="heading">
						<div className="heading-left">
							<h1 className="title">Fichajes</h1>
						</div>

						<div className="heading-filters">
							<ButtonGroup className="hidden-xs hidden-sm" aria-label="Times view">
								<Button
									variant={`${TIME_LIST_VIEWS.list === time_list_view ? "primary" : "default"}`}
									onClick={() => this.handleChangeView(TIME_LIST_VIEWS.list)}
								>
									<FiList className="icon" />
								</Button>
								<Button
									variant={`${TIME_LIST_VIEWS.employee_day === time_list_view ? "primary" : "default"}`}
									onClick={() => this.handleChangeView(TIME_LIST_VIEWS.employee_day)}
								>
									<MdOutlineTimer className="icon" />
								</Button>
							</ButtonGroup>


							<Dropdown className="dropdown-filter" show={showFilterOptions} >
								<Dropdown.Toggle variant="action" onClick={() => this.toggleFilterOptions()} >
									<FiSliders className="icono-filtro" title="Más filtros" />
								</Dropdown.Toggle>
								<Dropdown.Menu alignRight>
									<Dropdown.Header>Más filtros</Dropdown.Header>

									<div className="dropdown-content">
										{!is_list_view &&
											<FormGroup
												className="form-group-flex form-switch"
												controlId="show_rest_time"
											>
												<Switch
													controlId="show_rest_time"
													isOn={show_rest_time}
													handleToggle={this.handleSwitch}
												/>
												<FormLabel title="Mostrar descansos entre fichajes">Mostrar descansos</FormLabel>
											</FormGroup>
										}

										<FormGroup
											className="form-group-flex form-switch"
											controlId="not_ended"
										>
											<Switch
												controlId="not_ended"
												isOn={not_ended}
												handleToggle={this.handleSwitch}
											/>
											<FormLabel title="Mostrar fichajes abiertos">Mostrar abiertos</FormLabel>
										</FormGroup>

										<FormGroup
											className="form-group-flex form-switch"
											controlId="filter_approved"
										>
											<Switch
												controlId="filter_approved"
												isOn={filter_approved}
												handleToggle={this.handleSwitch}
											/>
											<FormLabel title="Mostrar fichajes pendientes de aprobación">Mostrar sin aprobar</FormLabel>
										</FormGroup>

										<FormGroup className="form-group-side" controlId="filter_alertas">
											<FormLabel>
												Alertas
											</FormLabel>
											<FormControl
												type="text"
												value={filter_alertas}
												placeholder="Alertas"
												onChange={this.handleChangeSearch}
												as="select"
											>
												<option value="-1">Mostrar todos</option>
												<option value="0">Mostrar fichajes sin alertas</option>
												<option value="1">Mostrar fichajes con alertas</option>
											</FormControl>
										</FormGroup>
									</div>
									<div className="dropdown-footer">
										<button
											type="button"
											className="btn btn-outline-primary"
											onClick={() => this.toggleFilterOptions()}
										>
											Cerrar
										</button>
									</div>
								</Dropdown.Menu>
							</Dropdown>



							<FormGroup
								className="form-select-user"
								controlId="searchEmployee"
							>
								<FormControl
									type="text"
									placeholder="Buscar..."
									value={this.state.searchEmployee}
									className={!isAdmin() && !isSupervisor() || select_employees.length < 1 ? 'hidden' : ''}
									onChange={this.handleChangeSearch}
									as="select"
								>
									<option value="">Todos los empleados</option>
									{employeeList}
								</FormControl>
							</FormGroup>

							<FormGroup className="form-search" controlId="filterGroupId">
								<FormControl
									type="text"
									placeholder="Buscar..."
									value={this.state.filterGroupId}
									onChange={this.handleChangeSearch}
									as="select"
								>
									<option value="">Filtrar por grupo</option>
									{optionList}
								</FormControl>
							</FormGroup>

							<DateRangePicker
								startDateId="startDateId"
								endDateId="endDateId"
								startDate={
									this.state.startDate ? moment(this.state.startDate) : null
								}
								endDate={this.state.endDate ? moment(this.state.endDate) : null}
								startDatePlaceholderText="Inicio"
								endDatePlaceholderText="Fin"
								onDatesChange={this.onDatesChange}
								focusedInput={this.state.focusedInput}
								hideKeyboardShortcutsPanel={true}
								isOutsideRange={() => false}
								minimumNights={0}
								onFocusChange={(focusedInput) =>
									this.setState({ focusedInput })
								}
							/>
						</div>

						<div className="heading-actions">
							<Link
								to={`/times/${this.props.user.id}/employee`}
								title="Registrar tiempos"
								className="btn btn-new"
							>
								<FiPlus />
							</Link>

							<Dropdown>
								<Dropdown.Toggle variant="action">
									<FiMoreHorizontal />
								</Dropdown.Toggle>
								<Dropdown.Menu alignRight>
									<Dropdown.Item
										as="button"
										onClick={() => this.handleExport()}
									>
										<FiUpload className="dropdown-icon" /> Exportar a excel

									</Dropdown.Item>

									<Dropdown.Item
										as="button"
										onClick={() => this.handleExportIncludeAllWorkflows()}
									>
										<FiUpload className="dropdown-icon" /> Exportar a excel con todas las ausencias
									</Dropdown.Item>

									<Dropdown.Item
										as="button"
										onClick={() => this.handlePeriodApproval()}
									>
										<FiClock className="dropdown-icon" /> Aprobar todos los fichajes filtrados

									</Dropdown.Item>

									<FormGroup
										className="form-switch export-config"
										controlId="include_approved_workflows"
									>
										<Switch
											controlId="include_approved_workflows"
											isOn={include_approved_workflows}
											handleToggle={this.handleSwitch}
										/>
										<FormLabel>Incluir en la exportación las ausencias y vacaciones aprobadas</FormLabel>
									</FormGroup>
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</div>
					<div className="subheading">
						<span className="summary">
							<FiClock className="summary-icon" />
							<span>total {" "} <strong>{total}</strong> | total trabajado{" "} <strong>{total_working}</strong></span>
						</span>
					</div>

					{this.getContent()}

					<Modal hideModal={this.props.hideModal} />
				</Layout>
			</>
		);
	}
}

const mapStateToProps = (reducers) => {
	return {
		...reducers.timesReducer,
		...reducers.employeesReducer,
		...reducers.groupsReducer,
		...reducers.modalReducer,
		user: reducers.authReducer.user,
		loading: reducers.timesReducer.loading,
	};
};

const mapDispatchToProps = (dispatch) => ({
	...timesActions,
	hideModal: () => dispatch(hideModal()),
	showModal: (modalProps, modalType) => {
		dispatch(showModal({ modalProps, modalType }));
	},
	updateModalLoading: (isLoading) => dispatch(updateModalLoading(isLoading)),
	getSelectableEmployees: (options) => dispatch(employeesActions.getSelectableEmployees(options)),
	getAllGroups: () => dispatch(groupsActions.getAllGroups({ sort: "+name" })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Times);
