import moment from 'moment';


export function isValidDate(d) {
	return d instanceof Date && !isNaN(d);
}

export function formatMinutesToTime(minutes) {
	let hours = parseInt(minutes / 60);
	let mins = parseInt(minutes % 60);
	let sign = "";

	if (hours >= 0 && mins < 0)
		sign = "-";

	if (mins < 0)
		mins = mins * (-1);

	if (mins < 10)
		mins = '0' + mins;

	return `${sign}${hours}h ${mins}'`;
}

export function formatSecondsToTime(seconds, format) {
	let minutes = parseInt(seconds / 60);
	let hours = parseInt(minutes / 60);

	let mins = minutes % 60;
	if (mins > 0 && mins < 10)
		mins = '0' + mins;

	let secs = seconds % 60;
	if (secs < 10)
		secs = '0' + secs;

	if (format === "hm") {
		return mins === 0 ? `${hours}h` : `${hours}h ${mins}m`;
	}
	else if (format === "hms") {
		return `${hours}h ${mins}m ${secs}s`;
	}
	else
		return `${hours}:${mins}:${secs}`;
}

export function getDiffTime(start, end, type) {
	let totalTime = 0;
	if (type === undefined)
		type = "minutes";

	if (start != null && end != null) {

		let endTime = moment.utc(end).local();
		let startTime = moment.utc(start).local();

		totalTime = endTime.diff(startTime, type);

		if (isNaN(totalTime))
			totalTime = 0;
	}

	return totalTime;
}

export function getDiffDays(start, end) {
	let totalDays = 0;

	if (end == null) {
		totalDays = 1;
	}
	else {
		const endTime = moment.utc(end).local();
		const startTime = moment.utc(start).local();
		totalDays = endTime.diff(startTime, "days") + 1;
	}

	return totalDays;
}

export function getWorkedTime(times, date) {
	var totalSeconds = getWorkedTimeRaw({ times, date, unit: "seconds" });
	const formatedTime = formatSecondsToTime(totalSeconds, "hm");

	return formatedTime;
}

export function getWorkedTimeRaw(params) {
	var totalTime = 0;
	const { times, date, unit, employee_id } = params;

	times.filter(time => {
		if (employee_id && time.employee.id !== employee_id)
			return false;
		return date ? moment.utc(time.start).local().format("DD/MM/YYYY") === moment.utc(date).local().format("DD/MM/YYYY") : time
	}).forEach((time) => {
		// just calc if it's work_time
		if (time.type !== undefined && time.type.work_time) {
			var getDiff = getDiffTime(time.start, time.end, unit);
			// console.log("getDiff", getDiff);
			// console.log("start", time.start);
			// console.log("end", time.end);
			totalTime += getDiff;
		}
	});

	return totalTime;
}


export function getBalanceHours(objective_hours, total_today) {

	// Convertir objective_hours a número, asegurando que la coma decimal se convierta en punto
	objective_hours = Number(objective_hours.toString().replace(",", "."));
	total_today = Number(total_today);

	// Verificar si la conversión fue exitosa
	if (isNaN(objective_hours) || isNaN(total_today)) {
		console.error("Error: objective_hours o total_today no son números válidos.");
		return { short: "0", short_raw: 0, formated: "00h 00m" };
	}

	const objective_seconds = objective_hours * 3600;
	const hours_today = total_today / 3600;
	let totalSeconds = total_today - objective_seconds;
	// console.log({ objective_hours });
	// console.log({ total_today });
	// console.log({ totalSeconds });

	const formatedTime = formatSecondsToTime(totalSeconds, "hm");
	let short = 0;
	let short_raw = 0;
	// console.log(formatedTime);

	// Si el total de hoy es superior a las horas objetivo
	if (total_today > objective_seconds) {
		short_raw = total_today / 3600 - objective_hours;
		if (short_raw < 1 && short_raw >= 0.01667) { // Si es menos de una hora pero más de un minuto
			const minutes = short_raw * 60;
			short = Math.round(minutes).toString() + "m"; // Redondeamos para evitar errores
		} else if (short_raw >= 1) { // Más de una hora
			const formattedValue = new Intl.NumberFormat("de-DE", { maximumSignificantDigits: 2 }).format(short_raw);
			short = "+" + formattedValue.toString() + "h";
		} else if (short_raw < 0.01667) {
			// Mostramos "0" si es menos de un minuto
			short = "0";
		}
	}
	// Si el total de hoy es inferior a las horas objetivo
	else if (total_today < objective_seconds) {
		short_raw = hours_today - objective_hours;
		if (short_raw < -0.01667 && short_raw > -1) { // Si es menos de una hora negativa
			const minutes = Math.abs(short_raw * 60);
			short = "-" + Math.round(minutes).toString() + "m"; // Redondeamos minutos negativos
		} else if (short_raw <= -1) { // Más de una hora negativa
			const formattedValue = new Intl.NumberFormat("de-DE", { maximumSignificantDigits: 2 }).format(short_raw);
			short = formattedValue + "h"; // Negativo en horas
		} else if (short_raw > -0.01667) {
			// Mostramos "0" si es menos de un minuto negativo
			short = "0";
		}
	}

	const balance_hours = {
		short,
		short_raw,
		formated: formatedTime
	};

	return balance_hours;
}




// gets a time list and extracts total seconds
export function getWorkedTimeCounter(times) {
	var totalTime = 0;

	times.forEach((time) => {
		if (time.type !== undefined && time.type.work_time) {
			totalTime += getDiffTime(time.start, time.end, "seconds");
		}
	});

	const formatedTime = formatSecondsToTime(totalTime);
	return formatedTime;
}

// gets a time list and extracts total seconds
export function getTimeCounterByType(times, type) {
	var totalTime = 0;

	times.forEach((time) => {
		if (time.type !== undefined && time.type.id === type.id) {
			totalTime += getDiffTime(time.start, time.end, "minutes");
		}
	});

	return totalTime;
}



// Gets first start and last end from a list of time intervals
export function getFirstLastInterval(timeIntervals, isSelect) {
	if (!Array.isArray(timeIntervals) || timeIntervals.length === 0) {
		return '';
	}

	// Convert time (HH:mm) to minutes from start of the day
	const timeToMinutes = (time) => {
		const [hours, minutes] = time.split(':').map(Number);
		return hours * 60 + minutes;
	};

	// Convert minutes back to HH:mm format
	const minutesToTime = (minutes) => {
		const totalMinutes = minutes % (24 * 60); // Wrap around for cases > 1440
		const hours = Math.floor(totalMinutes / 60);
		const mins = totalMinutes % 60;
		return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
	};

	// Convertir desde UTC a la zona horaria local
	const convertedIntervals = timeIntervals.map(({ start, end }) => ({
		start: convertFromUTC(start),
		end: convertFromUTC(end),
	}));

	// Normalize intervals to handle crossing midnight
	const normalizedIntervals = convertedIntervals.map(({ start, end }) => {
		const startMinutes = timeToMinutes(start);
		let endMinutes = timeToMinutes(end);

		// Si la hora de fin es menor o igual que la de inicio, el turno cruza medianoche
		if (endMinutes <= startMinutes) {
			endMinutes += 24 * 60; // Agregar un día (1440 minutos)
		}

		return { start: startMinutes, end: endMinutes };
	});

	// Encontrar el inicio más temprano y el final más tarde
	let firstStart = normalizedIntervals[0].start;
	let lastEnd = normalizedIntervals[0].end;

	for (let i = 1; i < normalizedIntervals.length; i++) {
		if (normalizedIntervals[i].start < firstStart) {
			firstStart = normalizedIntervals[i].start;
		}
		if (normalizedIntervals[i].end > lastEnd) {
			lastEnd = normalizedIntervals[i].end;
		}
	}

	if (isSelect) {
		return `🕒 ${minutesToTime(firstStart)} - ${minutesToTime(lastEnd)}`;
	}


	// Convertir resultados de vuelta a HH:mm formato local
	return (<span className="shift-times">
		<span className="clock-icon">🕒</span> {minutesToTime(firstStart)}<span className="separador">-</span>{minutesToTime(lastEnd)}
	</span>);
}


// gets first start and last end from a list of time intervals
export function calculateWorkTime(intervals) {
	let totalSeconds = 0;

	intervals.forEach(interval => {
		if (interval.time_type.work_time) {
			// Convertimos la hora desde UTC a la zona horaria local
			const localStart = convertFromUTC(interval.start);
			const localEnd = convertFromUTC(interval.end);

			const [startHours, startMinutes] = localStart.split(':').map(Number);
			const [endHours, endMinutes] = localEnd.split(':').map(Number);

			let startInSeconds = (startHours * 3600) + (startMinutes * 60);
			let endInSeconds = (endHours * 3600) + (endMinutes * 60);

			// Si la hora de fin es menor que la de inicio, significa que el turno cruza medianoche
			if (endInSeconds < startInSeconds) {
				endInSeconds += 24 * 3600; // Añadir 24 horas en segundos
			}

			totalSeconds += endInSeconds - startInSeconds;
		}
	});

	return totalSeconds;
}

// Convertir la hora ingresada a UTC antes de guardarla en la base de datos
export function convertToUTC(time) {
	if (!time || !moment(time, "HH:mm", true).isValid()) return ""; // Manejar valores vacíos o inválidos

	return moment.utc(moment(time, "HH:mm")).format("HH:mm[Z]");
}

// Convertir de UTC a la zona local del usuario antes de mostrarlo en la UI
export function convertFromUTC(time) {
	if (!time || !moment(time, "HH:mmZ", true).isValid()) return "";

	return moment.utc(time, "HH:mmZ").local().format("HH:mm");
}