import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { BrowserRouter as Router } from 'react-router-dom';
import { PrivateRoute } from "components/PrivateRoute";
import { authService } from "services";

import Dashboard from "pages/Dashboard";
import Times from "pages/Times";
import TimesWithout from "pages/TimesWithout";
import Register from "pages/Register";
import Login from "pages/Login";
import RememberPassword from "pages/RememberPassword";
import NewPassword from "pages/NewPassword";
import Invitation from "pages/Invitation";

import Clockin from "pages/Clockin";

import NotFound from "pages/NotFound";
import Profile from "pages/Profile";
import CustomFields from "pages/CustomFields";
import Employee from "pages/Employee";
import Employees from "pages/Employees";
import EmployeeFunctions from "pages/EmployeeFunctions";
import EmployeeUbications from "pages/EmployeeUbications";
import EmployeeImports from "pages/EmployeeImports";
import EmployeeNew from "pages/EmployeeNew";
import EmployeeShiftPatternsImports from "pages/EmployeeShiftPatternsImports";
import EmployeeFunctionsImports from "pages/EmployeeFunctionsImports";
import EmployeeUbicationsImports from "pages/EmployeeUbicationsImports";
import Forecasting from "pages/Forecasting";
import FunctionsImports from "pages/FunctionsImports";
import UbicationsImports from "pages/UbicationsImports";
import TimeRegistrations from "pages/TimeRegistrations";
import Group from "pages/Group";
import Groups from "pages/Groups";
import StaffingLevels from "pages/StaffingLevels";
import Absences from "pages/Absences";
import AbsencesPending from "pages/AbsencesPending";
import AbsencesManaged from "pages/AbsencesManaged";
import AbsenceImports from "pages/AbsenceImports";
import Documents from "pages/Documents";
import Shifts from "pages/Shifts";
import ShiftPatterns from "pages/ShiftPatterns";
import ShiftsImports from "pages/ShiftsImports";
import ShiftPatternsImports from "pages/ShiftPatternsImports";
import StaffingLevelsImports from "pages/StaffingLevelsImports";
import Calendars from "pages/Calendars";
import CalendarImports from "pages/CalendarImports";
import Calendar from "pages/Calendar";
import DayTypes from "pages/DayTypes";
import TimeTypes from "pages/TimeTypes";
import Accounts from "pages/Accounts";
import Contracts from "pages/Contracts";
import Plans from "pages/Plans";
import Plan from "pages/Plan";
import PlanMagic from "pages/PlanMagic";
import PlanViews from "pages/PlanViews";
import PlanImports from "pages/PlanImports";

import Rules from "pages/Rules";
import Transactions from "pages/Transactions";

import MyCompany from "pages/MyCompany";
import MySubscription from "pages/MySubscription";
import EndTrial from "pages/EndTrial";
import Restricted from "pages/Restricted";
import WidgetTemplates from "pages/WidgetTemplates";
import * as userActions from "actions/userActions";
import Loading from 'components/Loading';
import "styles/App.scss";


function App() {
	const dispatch = useDispatch(); // Hook para despachar acciones
	const [loading, setLoading] = useState(true); // Estado para manejar la carga
	const domain = window.location.hostname;
	const domainSpecificRoutes = (domain === 'haddock.local' || domain === 'hr.haddock.app') ? [
		<Route
			key="restricted-my-subscription"
			exact
			path="/my-subscription"
			component={Restricted}
		/>,
		<Route
			key="restricted-contract-plans"
			exact
			path="/contract-plans"
			component={Restricted}
		/>,
	] : [
		<PrivateRoute
			key="private-my-subscription"
			exact
			path="/my-subscription"
			roles={["ADMIN"]}
			component={MySubscription}
		/>,
		<PrivateRoute
			key="private-contract-plans"
			exact
			path="/contract-plans"
			roles={["ADMIN"]}
			component={MySubscription}
		/>
	];

	useEffect(() => {
		async function performSSO() {
			try {
				if (authService.isFromHaddock) {
					await dispatch(userActions.sso());
					console.log('SSO completado');
				}

				// Lógica para verificar el dominio y aplicar el skin
				// const skinFolder = localStorage.getItem('skin');
				// console.log(skinFolder);
				var skinFolder = 'default';

				if (!domain.includes('plain') && !domain.includes('localhost')) {

					if (domain === 'haddock.local' || domain === 'hr.haddock.app') {
						skinFolder = 'haddock';
						document.title = 'haddock HR';

						const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
						link.type = 'image/x-icon';
						link.rel = 'shortcut icon';
						link.href = `/${skinFolder}/favicon.ico`;
						// Añadir el favicon al documento si no existe
						document.getElementsByTagName('head')[0].appendChild(link);

						// Configurar único ícono para Safari iOS
						const appleLink = document.createElement('link');
						appleLink.rel = 'apple-touch-icon';
						appleLink.href = `/${skinFolder}/apple-touch-icon.png`; // Asume 180x180 px
						document.getElementsByTagName('head')[0].appendChild(appleLink);
					}

					// El dominio no contiene "plain" ni "localhost"
					document.documentElement.classList.add(`custom-domain`);
					document.documentElement.classList.add(`custom-${skinFolder}`);
					localStorage.setItem('skin', skinFolder);

					// console.log(domainSpecificRoutes);
				}

			} catch (error) {
				console.log('Error en SSO:', error);
			} finally {
				setLoading(false); // Independientemente del resultado, terminamos la carga
			}
		}

		performSSO(); // Llama a performSSO si viene de Haddock
	}, [dispatch]);

	// Mientras estamos en proceso de SSO, mostramos un indicador de carga
	if (loading) {
		return <Loading />;
	}

	return (
		<Router>
			<Switch>
				<PrivateRoute exact path="/" component={Dashboard} />
				<Route exact path="/login" component={Login} />
				<Route exact path="/register" component={Register} />
				<Route exact path="/remember-password" component={RememberPassword} />
				<Route exact path="/remember-password/:token" component={NewPassword} />
				<Route exact path="/invitation/:token" component={Invitation} />
				<Route exact path="/end-trial" component={EndTrial} />
				<Route exact path="/restricted" component={Restricted} />

				<Route exact path="/clockin" component={Clockin} />

				{domainSpecificRoutes}


				{/* LOGGED IN, ALL USERS */}
				<PrivateRoute path="/my-times" component={TimeRegistrations} />
				<PrivateRoute exact path="/profile" component={Profile} />
				<PrivateRoute exact path="/absences/" component={Absences} />
				<PrivateRoute exact path="/plans/" component={Plans} />
				<PrivateRoute exact path="/plans/:group/:start@:end" component={Plan} />
				<PrivateRoute exact path="/calendar/" component={Calendar}
				/>

				{/* ADMIN ONLY */}
				<PrivateRoute
					path="/times/:id/employee"
					roles={["ADMIN", "SUPERVISOR"]}
					component={TimeRegistrations}
				/>
				<PrivateRoute exact path="/intelligent-plan/:group/:start@:end" component={PlanMagic} />
				<PrivateRoute exact path="/pending-absences" roles={["ADMIN", "SUPERVISOR"]} component={AbsencesPending} />
				<PrivateRoute exact path="/managed-absences" roles={["ADMIN", "SUPERVISOR"]} component={AbsencesManaged} />
				<PrivateRoute exact path="/times" roles={["ADMIN", "SUPERVISOR"]} component={Times} />
				<PrivateRoute exact path="/without-time" roles={["ADMIN", "SUPERVISOR"]} component={TimesWithout} />
				<PrivateRoute
					exact
					path="/employees"
					roles={["ADMIN"]}
					component={Employees}
				/>
				<PrivateRoute
					exact
					path="/custom-fields"
					roles={["ADMIN"]}
					component={CustomFields}
				/>
				<PrivateRoute
					exact
					path="/employee-functions"
					roles={["ADMIN"]}
					component={EmployeeFunctions}
				/>
				<PrivateRoute
					exact
					path="/employees/new"
					roles={["ADMIN"]}
					component={EmployeeNew}
				/>
				<PrivateRoute
					exact
					path="/employees/import"
					roles={["ADMIN"]}
					component={EmployeeImports}
				/>
				<PrivateRoute
					exact
					path="/employees/shift-patterns/import"
					roles={["ADMIN"]}
					component={EmployeeShiftPatternsImports}
				/>
				<PrivateRoute
					exact
					path="/employees/functions/import"
					roles={["ADMIN"]}
					component={EmployeeFunctionsImports}
				/>
				<PrivateRoute
					exact
					path="/employees/ubications/import"
					roles={["ADMIN"]}
					component={EmployeeUbicationsImports}
				/>
				<PrivateRoute
					exact
					path="/functions/import"
					roles={["ADMIN"]}
					component={FunctionsImports}
				/>
				<PrivateRoute
					exact
					path="/ubications/import"
					roles={["ADMIN"]}
					component={UbicationsImports}
				/>
				<PrivateRoute
					path="/employees/:id"
					roles={["ADMIN"]}
					component={Employee}
				/>
				<PrivateRoute
					exact
					path="/absences/import"
					roles={["ADMIN", "SUPERVISOR"]}
					component={AbsenceImports}
				/>
				<PrivateRoute
					exact
					path="/groups/"
					roles={["ADMIN"]}
					component={Groups}
				/>
				<PrivateRoute
					exact
					path="/groups/:id"
					roles={["ADMIN"]}
					component={Group}
				/>
				<PrivateRoute
					exact
					path="/staffing-levels/"
					roles={["ADMIN"]}
					component={StaffingLevels}
				/>
				<PrivateRoute
					exact
					path="/shift-patterns/import"
					roles={["ADMIN"]}
					component={ShiftPatternsImports}
				/>
				<PrivateRoute
					exact
					path="/staffing-levels/import"
					roles={["ADMIN"]}
					component={StaffingLevelsImports}
				/>
				<PrivateRoute
					exact
					path="/shifts/import"
					roles={["ADMIN"]}
					component={ShiftsImports}
				/>

				<PrivateRoute
					exact
					path="/shifts/"
					roles={["ADMIN"]}
					component={Shifts}
				/>
				<PrivateRoute
					exact
					path="/shift-patterns/"
					roles={["ADMIN"]}
					component={ShiftPatterns}
				/>
				<PrivateRoute
					exact
					path="/time-types/"
					roles={["ADMIN"]}
					component={TimeTypes}
				/>
				<PrivateRoute
					exact
					path="/rules/"
					roles={["ADMIN"]}
					component={Rules}
				/>
				<PrivateRoute
					exact
					path="/transactions/:employee/:account/:start@:end"
					roles={["ADMIN"]}
					component={Transactions}
				/>
				<PrivateRoute
					exact
					path="/transactions/"
					roles={["ADMIN"]}
					component={Transactions}
				/>
				<PrivateRoute
					exact
					path="/calendars/"
					roles={["ADMIN"]}
					component={Calendars}
				/>
				<PrivateRoute
					exact
					path="/calendars/import"
					roles={["ADMIN"]}
					component={CalendarImports}
				/>
				<PrivateRoute
					exact
					path="/day-types/"
					roles={["ADMIN"]}
					component={DayTypes}
				/>
				<PrivateRoute
					exact
					path="/accounts/"
					roles={["ADMIN"]}
					component={Accounts}
				/>
				<PrivateRoute
					exact
					path="/contracts/"
					roles={["ADMIN"]}
					component={Contracts}
				/>

				<PrivateRoute
					exact
					path="/my-company"
					roles={["ADMIN"]}
					component={MyCompany}
				/>

				<PrivateRoute
					exact
					path="/plan-views/"
					roles={["ADMIN", "SUPERVISOR"]}
					component={PlanViews}
				/>

				<PrivateRoute
					exact
					path="/plans/import"
					roles={["ADMIN"]}
					component={PlanImports}
				/>

				<PrivateRoute
					exact
					path="/forecasting"
					roles={["ADMIN"]}
					component={Forecasting}
				/>


				<PrivateRoute exact path="/documents" component={Documents} />
				<PrivateRoute exact path="/employee-ubications" component={EmployeeUbications} />
				<PrivateRoute
					exact
					path="/documents/:category/"
					component={Documents}
				/>

				<PrivateRoute
					exact
					path="/widget-templates/"
					roles={["ADMIN", "SUPERVISOR"]}
					component={WidgetTemplates}
				/>


				{/* NOT FOUND */}
				<PrivateRoute component={NotFound} />
			</Switch>
		</Router>
	);
}

export default App;

{
	/* <PrivateRoute
exact
path="/documents/"
roles={"ADMIN"}
component={Documents}
/>  */
}
