import { handleResponse, handleBlobResponse } from "utils/utils";
import { authService } from "services";

export const shiftPatternsService = {
	getAll,
	getSelectable,
	getById,
	add,
	update,
	remove,
	clone,
	downloadImportTemplate,
	prepareImport,
	runImport,
};

// gets all shift patterns
function getAll(options) {
	const requestOptions = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	if (!options)
		options = {}

	const { q, enabled } = options;

	let query = "?sort=+name&page_size=10000";

	if (q !== undefined)
		query += "&q=" + q;

	if (enabled)
		query += "&enabled=" + enabled;

	return fetch(`${global.BASE_API}shift-patterns${query}`, requestOptions).then(
		handleResponse
	);
}

// gets selectable shift patterns
function getSelectable() {
	const requestOptions = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	// let query = '?sort=+id&variant=select&enabled=true';
	let query = "?sort=+name&enabled=true&page_size=10000";

	return fetch(`${global.BASE_API}shift-patterns${query}`, requestOptions).then(
		handleResponse
	);
}

// get one shift pattern by id
function getById(id) {
	const requestOptions = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	return fetch(`${global.BASE_API}shift-patterns/${id}`, requestOptions).then(
		handleResponse
	);
}

// add new shift pattern
function add(shiftPattern) {
	const requestOptions = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(shiftPattern),
	};

	return fetch(`${global.BASE_API}shift-patterns/`, requestOptions).then(
		handleResponse
	);
}

// update shift pattern
function update(shiftPattern) {
	const requestOptions = {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(shiftPattern),
	};

	return fetch(
		`${global.BASE_API}shift-patterns/${shiftPattern.id}`,
		requestOptions
	).then(handleResponse);
}

// remove shift pattern
function remove(id) {
	const requestOptions = {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	return fetch(`${global.BASE_API}shift-patterns/${id}`, requestOptions).then(
		handleResponse
	);
}

// clone shift pattern
function clone(id) {
	const requestOptions = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	return fetch(`${global.BASE_API}shift-patterns/${id}/clone`, requestOptions).then(
		handleResponse
	);
}


/**********************************************************************
 ****** IMPORT SHIFT PATTERNS
 ***********************************************************************/

function downloadImportTemplate() {
	const requestOptions = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
			"Content-Type": "application/json",
		},
	};

	return fetch(
		`${global.BASE_API}shift-patterns/imports/template`,
		requestOptions
	).then(handleBlobResponse);
}

function prepareImport(formData) {
	const requestOptions = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
		body: formData,
	};

	return fetch(`${global.BASE_API}shift-patterns/imports`, requestOptions).then(
		handleResponse
	);
}

function runImport(token) {
	const requestOptions = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	return fetch(
		`${global.BASE_API}shift-patterns/imports/${token}`,
		requestOptions
	).then(handleResponse);
}