// FloatingBar.js
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { PiSelectionSlashBold } from "react-icons/pi";

const FloatingBar = ({ selectedCount, onClear, onAction }) => {

	// Efecto para añadir o quitar la clase en el body
	useEffect(() => {
		if (selectedCount > 0) {
			document.body.classList.add("has-floating");
		} else {
			document.body.classList.remove("has-floating");
		}

		// Cleanup para evitar clases residuales al desmontar el componente
		return () => {
			document.body.classList.remove("has-floating");
		};
	}, [selectedCount]);

	return (
		<div className={`floating-bar ${selectedCount > 0 ? "visible" : ""}`}>
			<span>
				{selectedCount === 1
					? "Tienes 1 elemento seleccionado"
					: `Tienes ${selectedCount} elementos seleccionados`}
			</span>
			<div className="actions">
				<button className="button-link primary" onClick={onClear}><PiSelectionSlashBold /> Desmarcar selección</button>
				{/* <button onClick={onAction}>Eliminar</button> */}
			</div>
		</div>
	);
};


FloatingBar.propTypes = {
	selectedCount: PropTypes.number.isRequired,
	onClear: PropTypes.func.isRequired,
	onAction: PropTypes.func.isRequired,
};

export default FloatingBar;