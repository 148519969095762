import { handleResponse, } from "utils/utils";
import { authService } from "services";

export const iPlanService = {
	updateMagicPlan,
	salesForecast,
	staffingLevelsForecast,
};

function updateMagicPlan(iplan) {
	const requestOptions = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(iplan),
	};

	return fetch(
		`${global.REACT_APP_MAGIC_URL}intelligent-planning/assignments`,
		//`https://plan.plain.ninja/saas/api/intelligent-planning/assignments`,
		requestOptions
	).then(handleResponse);
}


function salesForecast(formData) {
	const requestOptions = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
		body: formData,
	};

	return fetch(
		`${global.BASE_API}intelligent-forecast/sales-forecast`, requestOptions
	).then(handleResponse);
}

function staffingLevelsForecast(formData) {
	const requestOptions = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
		body: formData,
	};

	return fetch(
		`${global.BASE_API}intelligent-forecast/staffing-levels`, requestOptions
	).then(handleResponse);
}