import { handleResponse, handleBlobResponse } from "utils/utils";
import { authService } from "services";

export const shiftsService = {
    getAll,
    getDefault,
    getSelectable,
    getByAttendance,
    getById,
    add,
    update,
    remove,
    getTypes,
    downloadImportTemplate,
    prepareImport,
    runImport,
};

// gets all shifts
function getAll(options) {
    const { q, shift_type, group_id, contract_only } = options;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`
        },
    };
    let query = '?sort=+name&page_size=10000';

    if (q !== undefined)
        query += "&q=" + q;

    if (shift_type !== undefined)
        query += "&shift_type=" + shift_type;

    if (group_id !== undefined)
        query += "&group_id=" + group_id;

    if (contract_only !== undefined)
        query += "&contract_only=" + contract_only;


    return fetch(`${global.BASE_API}shifts${query}`, requestOptions).then(handleResponse);
}

// get default shift
function getDefault() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`
        },
    };
    return fetch(`${global.BASE_API}shifts/default`, requestOptions).then(handleResponse);
}

// gets selectable shifts
function getSelectable(options) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`
        },
    };

    let query = '?sort=+name&variant=select&enabled=true&page_size=10000';

    if (options) {
        const { q, shift_type, group_id } = options;

        if (q !== undefined)
            query += "&q=" + q;

        if (shift_type !== undefined)
            query += "&shift_type=" + shift_type;

        if (group_id !== undefined)
            query += "&group_id=" + group_id;
    }

    return fetch(`${global.BASE_API}shifts${query}`, requestOptions).then(handleResponse);
}

function getByAttendance(isAttendance) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`
        },
    };
    const shift_type = isAttendance ? "Trabajo" : "Ausencia,Vacaciones"

    let query = '?sort=+name&variant=select&enabled=true&shift_type=' + shift_type;

    return fetch(`${global.BASE_API}shifts${query}`, requestOptions).then(handleResponse);
}

// get one shift by id
function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`
        },
    };

    return fetch(`${global.BASE_API}shifts/${id}`, requestOptions).then(handleResponse);
}

// add new shift
function add(shift) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(shift)
    };

    return fetch(`${global.BASE_API}shifts/`, requestOptions).then(handleResponse);
}

// update shift
function update(shift) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(shift)
    };

    return fetch(`${global.BASE_API}shifts/${shift.id}`, requestOptions).then(handleResponse);
}

// remove shift
function remove(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`
        },
    };

    return fetch(`${global.BASE_API}shifts/${id}`, requestOptions).then(handleResponse);
}


// get shift types
function getTypes() {

    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`
        },
    };

    return fetch(`${global.BASE_API}shifts/types`, requestOptions).then(handleResponse);
}


/**********************************************************************
 ****** IMPORT SHIFTS
 ***********************************************************************/

function downloadImportTemplate() {
    const requestOptions = {
        method: "GET",
        headers: {
            Authorization: `Bearer ${authService.token.access_token}`,
            "Content-Type": "application/json",
        },
    };

    return fetch(
        `${global.BASE_API}shifts/imports/template`,
        requestOptions
    ).then(handleBlobResponse);
}

function prepareImport(formData) {
    const requestOptions = {
        method: "POST",
        headers: {
            Authorization: `Bearer ${authService.token.access_token}`,
        },
        body: formData,
    };

    return fetch(`${global.BASE_API}shifts/imports`, requestOptions).then(
        handleResponse
    );
}

function runImport(token) {
    const requestOptions = {
        method: "POST",
        headers: {
            Authorization: `Bearer ${authService.token.access_token}`,
        },
    };

    return fetch(
        `${global.BASE_API}shifts/imports/${token}`,
        requestOptions
    ).then(handleResponse);
}