import React, { Fragment } from 'react';
import moment from 'moment';
import { FaCaretUp, FaCaretDown } from "react-icons/fa";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { convertFromUTC } from 'utils/datetime';
function collect(props) {
	return props;
}

const getStaffingHourlyHeader = (props) => props.plan_days.map((d, i) => {
	const day = moment(d.day).format("dd");
	const dayNum = moment(d.day).format("DD");
	const details = d.details;

	// console.log(d);
	let day_type;

	// TODO adaptar multigrup
	if (details.length > 0) {
		if (details[0].special_day)
			day_type = details[0].special_day.day_type;

		if (details[0].weekday)
			day_type = details[0].weekday.day_type;
	}

	const color = day_type.color;

	return (
		<Fragment key={i}>
			<th
				className="th-day head-hours-main"
				style={{ color: color }}
			>
				<div className="th-wrap">
					<span className="span-day">{day}</span>
					<span className="span-num">{dayNum}</span>
				</div>
			</th>
			{getPlanHourlyRows(props)}
		</Fragment>
	)
});

// old: {h}-{h + 1}
const getPlanHourlyRows = (props) => props.working_hours_list.map((h, i) => {
	return (
		<th className="head-hours" key={i}>
			{h}h
		</th>
	)
});

const getStaffingFunctionRows = (props) => props.staffing_level_hourly_assignments.map((assignment, i) => {
	const function_name = assignment.function;

	return (
		<tr key={i}>
			<th>
				<div className="th-wrap">
					{function_name}
				</div>
			</th>
			{getHourlyCounterRow(props, assignment)}
		</tr>
	)
});

// contenido necesidades por cada dia y sus horas
const getHourlyCounterRow = (props, assignment) => assignment.counters.map((day, i) => {
	var day_totals = [];
	props.plan_assignments.map((pa, i) => {
		if (pa.employee.employee_with_function && pa.employee.employee_with_function.function.name === assignment.function) {
			const day_filter = pa.plan_assignments_hourly_totals.filter(t => t.day === day.day);

			if (day_filter.length > 0)
				day_totals.push(...day_filter[0].hours);
		}

		return true;
	});

	// console.log(day_totals);

	return (
		<Fragment key={i}>
			<td
				className="td-day"
			>
			</td>
			{getHourlyCounterRowContent(props, day, day_totals)}
		</Fragment>
	)
});

// contenido de las horas
// const getHourlyCounterRowContent = (props, day, day_totals) => day.hours.map((h, i) => {
// 	// console.log(day);
// 	// console.log(day.hours);
// 	// Convertimos la hora de UTC a la zona horaria local del usuario
// 	const local_hour = convertFromUTC(h.hour);
// 	const formated_hour = local_hour.substring(0, 2);

// 	// Filtrar los totales por la hora local ya convertida
// 	const hour_filter = day_totals.filter(d => d.hour === parseInt(formated_hour));
// 	let current = hour_filter.reduce((acc, numero) => acc + numero.count, 0);
// 	current = parseFloat(current).toFixed(2).toString().replace(".", ",").replace(",00", "");

// 	const needed = h.needed;
// 	const tooltip = <Tooltip className="tooltip-staffing">{current}/{needed}</Tooltip>;

// 	var classBackground = "";
// 	if (needed < current) {
// 		classBackground = "bg-green";
// 	} else if (needed > current) {
// 		classBackground = "bg-red";
// 	}

// 	return (
// 		<OverlayTrigger key={i} placement="top" overlay={tooltip}>
// 			<td key={i} className={`td-hour shift-cell ${classBackground}`}>
// 				{needed === 0 && current === 0 ? "-" : needed}
// 				{needed < current && (<FaCaretUp className="color-green-dark" />)}
// 				{needed > current && (<FaCaretDown className="color-red" />)}
// 			</td>
// 		</OverlayTrigger>
// 	);
// });

// TODO adaptar para que separe por función
// const getHourlyCounterRowContent = (props, day, day_totals) => {
// 	const processedHours = new Set();

// 	return day.hours.map((h, i) => {
// 		const local_hour = convertFromUTC(h.hour);
// 		const formatted_hour = local_hour.substring(0, 2);
// 		const isHalfHour = local_hour.includes(":30");

// 		// Si ya procesamos esta hora, la saltamos para evitar duplicaciones
// 		if (processedHours.has(formatted_hour)) return null;
// 		processedHours.add(formatted_hour);

// 		// Buscar si existe una media hora dentro de esta hora
// 		const halfHourEntry = day.hours.find(hh => convertFromUTC(hh.hour) === `${formatted_hour}:30`);

// 		// Obtener valores directamente desde los datos originales
// 		const fullHourNeeded = h.needed || 0;
// 		const fullHourCurrent = h.count !== undefined ? h.count : 0;

// 		const halfHourNeeded = halfHourEntry ? halfHourEntry.needed || 0 : 0;
// 		const halfHourCurrent = halfHourEntry ? (halfHourEntry.count !== undefined ? halfHourEntry.count : 0) : 0;

// 		// Tooltips correctos
// 		const tooltipFull = <Tooltip className="tooltip-staffing">{fullHourCurrent}/{fullHourNeeded}</Tooltip>;
// 		const tooltipHalf = halfHourEntry ? (
// 			<Tooltip className="tooltip-staffing">{halfHourCurrent}/{halfHourNeeded}</Tooltip>
// 		) : null;

// 		// Colores de fondo
// 		const classBackgroundFull = fullHourNeeded < fullHourCurrent ? "bg-green" : fullHourNeeded > fullHourCurrent ? "bg-red" : "";
// 		const classBackgroundHalf = halfHourEntry ? (halfHourNeeded < halfHourCurrent ? "bg-green" : halfHourNeeded > halfHourCurrent ? "bg-red" : "")
// 			: "";

// 		return (
// 			<td key={i} className={`td-hour shift-cell ${halfHourEntry ? "double-cell" : classBackgroundFull}`}>
// 				{halfHourEntry ? (
// 					<div className="hour-split">
// 						{/* Primera media hora */}
// 						<OverlayTrigger placement="top" overlay={tooltipFull}>
// 							<div className={`half-hour shift-cell ${classBackgroundFull}`}>
// 								{fullHourNeeded === 0 && fullHourCurrent === 0 ? "0" : fullHourNeeded}
// 								{fullHourNeeded < fullHourCurrent && (<FaCaretUp className="color-green-dark" />)}
// 								{fullHourNeeded > fullHourCurrent && (<FaCaretDown className="color-red" />)}
// 							</div>
// 						</OverlayTrigger>
// 						{/* Segunda media hora */}
// 						<OverlayTrigger placement="top" overlay={tooltipHalf}>
// 							<div className={`half-hour shift-cell ${classBackgroundHalf}`}>
// 								{halfHourNeeded === 0 && halfHourCurrent === 0 ? "0" : halfHourNeeded}
// 								{halfHourNeeded < halfHourCurrent && (<FaCaretUp className="color-green-dark" />)}
// 								{halfHourNeeded > halfHourCurrent && (<FaCaretDown className="color-red" />)}
// 							</div>
// 						</OverlayTrigger>
// 					</div>
// 				) : (
// 					<OverlayTrigger placement="top" overlay={tooltipFull}>
// 						<div className="single-hour">
// 							{fullHourNeeded === 0 && fullHourCurrent === 0 ? "0" : fullHourNeeded}
// 							{fullHourNeeded < fullHourCurrent && (<FaCaretUp className="color-green-dark" />)}
// 							{fullHourNeeded > fullHourCurrent && (<FaCaretDown className="color-red" />)}
// 						</div>
// 					</OverlayTrigger>
// 				)}
// 			</td>
// 		);
// 	});
// };

const getHourlyCounterRowContent = (props, day, day_totals) => {
	const processedHours = new Set();

	return day.hours.map((h, i) => {
		const local_hour = convertFromUTC(h.hour); // Ej: "06:30"
		const formatted_hour = local_hour.substring(0, 2); // "06"
		const isHalfHour = local_hour.includes(":30");

		// Saltar si ya procesamos esta hora
		if (processedHours.has(formatted_hour)) return null;
		processedHours.add(formatted_hour);

		// Buscar si hay media hora
		const halfHourEntry = day.hours.find(hh => convertFromUTC(hh.hour) === `${formatted_hour}:30`);

		// === CALCULAMOS EL CURRENT USANDO LOS day_totals ===
		const hour_filter_full = day_totals.filter(d => d.hour === parseInt(formatted_hour));
		let fullHourCurrent = hour_filter_full.reduce((acc, numero) => acc + numero.count, 0);
		fullHourCurrent = parseFloat(fullHourCurrent).toFixed(2).replace(".", ",").replace(",00", "");

		const fullHourNeeded = h.needed || 0;

		let halfHourCurrent = "";
		let halfHourNeeded = 0;

		if (halfHourEntry) {
			const hour_filter_half = day_totals.filter(d => {
				const hstr = d.hour.toString().padStart(2, "0");
				// Coincide con media hora exacta, comprobando existencia de esa celda
				return convertFromUTC(`${hstr}:30Z`) === `${formatted_hour}:30`;
			});

			const sumHalf = hour_filter_half.reduce((acc, numero) => acc + numero.count, 0);
			halfHourCurrent = parseFloat(sumHalf).toFixed(2).replace(".", ",").replace(",00", "");
			halfHourNeeded = halfHourEntry.needed || 0;
		}

		// === TOOLTIPS ===
		const tooltipFull = <Tooltip className="tooltip-staffing">{fullHourCurrent}/{fullHourNeeded}</Tooltip>;
		const tooltipHalf = halfHourEntry ? (
			<Tooltip className="tooltip-staffing">{halfHourCurrent}/{halfHourNeeded}</Tooltip>
		) : null;

		// === ESTILOS ===
		const classBackgroundFull = fullHourNeeded < parseFloat(fullHourCurrent.replace(",", ".")) ? "bg-green" :
			fullHourNeeded > parseFloat(fullHourCurrent.replace(",", ".")) ? "bg-red" : "";

		const classBackgroundHalf = halfHourEntry ? (
			halfHourNeeded < parseFloat(halfHourCurrent.replace(",", ".")) ? "bg-green" :
				halfHourNeeded > parseFloat(halfHourCurrent.replace(",", ".")) ? "bg-red" : ""
		) : "";

		return (
			<td key={i} className={`td-hour shift-cell ${halfHourEntry ? "double-cell" : classBackgroundFull}`}>
				{halfHourEntry ? (
					<div className="hour-split">
						<OverlayTrigger placement="top" overlay={tooltipFull}>
							<div className={`half-hour shift-cell ${classBackgroundFull}`}>
								{fullHourNeeded === 0 && fullHourCurrent === "0" ? "0" : fullHourNeeded}
								{fullHourNeeded < parseFloat(fullHourCurrent.replace(",", ".")) && (<FaCaretUp className="color-green-dark" />)}
								{fullHourNeeded > parseFloat(fullHourCurrent.replace(",", ".")) && (<FaCaretDown className="color-red" />)}
							</div>
						</OverlayTrigger>
						<OverlayTrigger placement="top" overlay={tooltipHalf}>
							<div className={`half-hour shift-cell ${classBackgroundHalf}`}>
								{halfHourNeeded === 0 && halfHourCurrent === "0" ? "0" : halfHourNeeded}
								{halfHourNeeded < parseFloat(halfHourCurrent.replace(",", ".")) && (<FaCaretUp className="color-green-dark" />)}
								{halfHourNeeded > parseFloat(halfHourCurrent.replace(",", ".")) && (<FaCaretDown className="color-red" />)}
							</div>
						</OverlayTrigger>
					</div>
				) : (
					<OverlayTrigger placement="top" overlay={tooltipFull}>
						<div className="single-hour">
							{fullHourNeeded === 0 && fullHourCurrent === "0" ? "0" : fullHourNeeded}
							{fullHourNeeded < parseFloat(fullHourCurrent.replace(",", ".")) && (<FaCaretUp className="color-green-dark" />)}
							{fullHourNeeded > parseFloat(fullHourCurrent.replace(",", ".")) && (<FaCaretDown className="color-red" />)}
						</div>
					</OverlayTrigger>
				)}
			</td>
		);
	});
};





const PlanStaffingHourly = (props) => {
	// console.log(props);

	if (props.staffing_level_hourly_assignments.length === 0) return "";

	return (
		<table id="tableNeeds" className={`table table-bordered table-plan table-needs table-hourly-counter`}>
			<thead>
				<tr>
					<th className="main-col"><div className="th-wrap">Necesidades</div></th>
					{getStaffingHourlyHeader(props)}
				</tr>
			</thead>
			<tbody>
				{getStaffingFunctionRows(props)}
			</tbody>
		</table>
	)
}

export default PlanStaffingHourly