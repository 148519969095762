import React, { Fragment } from "react"
import moment from 'moment';
import { InView } from 'react-intersection-observer';
import { PLAN_GRID_VIEWS } from "components/Plans";

// plan table render
const getPlanHeader = (props) => props.plan_days.map((d, i) => {
	//console.log(props);

	const day = moment(d.day).format("dd");
	const dayNum = moment(d.day).format("DD");
	const details = d.details;

	// console.log(d);
	let day_type;

	// TODO adaptar multigrup
	if (details.length > 0) {
		if (details[0].special_day)
			day_type = details[0].special_day.day_type;

		if (details[0].weekday)
			day_type = details[0].weekday.day_type;
	}


	const color = day_type.color;
	// Verificar si es el día actual
	const isToday = moment(d.day).isSame(moment(), 'day');

	return (
		<InView
			as="th"
			className={`th-day th-selectable ${isToday ? "today" : ""}`}
			key={i}
			style={{ color: color }}
			onChange={(inView, entry) => props.changeMonthHeader(inView, entry, d.day)}
			onClick={() => props.handleColumnSelection(i)}
		>
			<div className="th-wrap">
				<span className="span-day">{day}</span>
				<span className="span-num">{dayNum}</span>
			</div>
		</InView>
	)
});

// plan table render
const getPlanHourlyHeader = (props) => props.plan_days.map((d, i) => {
	//console.log(props);

	const day = moment(d.day).format("dd");
	const dayNum = moment(d.day).format("DD");

	const details = d.details;

	// console.log(d);
	let day_type;

	// TODO adaptar multigrup
	if (details.length > 0) {
		if (details[0].special_day)
			day_type = details[0].special_day.day_type;

		if (details[0].weekday)
			day_type = details[0].weekday.day_type;
	}

	const color = day_type.color;

	// Verificar si es el día actual
	const isToday = moment(d.day).isSame(moment(), 'day');

	return (
		<Fragment key={i}>
			<InView
				as="th"
				className={`th-day head-hours-main ${isToday ? "today" : ""}`}
				style={{ color: color }}
				onChange={(inView, entry) => props.changeMonthHeader(inView, entry, d.day)}
			>
				<div className="th-wrap">
					<span className="span-day">{day}</span>
					<span className="span-num">{dayNum}</span>
				</div>
			</InView>
			{getPlanHourlyRows(props)}
		</Fragment>
	)
});

// old: 	{h}-{h + 1}
const getPlanHourlyRows = (props) => props.working_hours_list.map((h, i) => {
	return (
		<th className="head-hours" key={i}>
			{h}h
		</th>
	)
});

const PlanTableHead = (props) => {
	const month = moment(props.current_month).format("MMMM YYYY");
	//console.log(props);
	if (props.plan_assignments.length === 0) return "";

	return (
		<thead>
			<tr>
				<th className="th-day">
					<div className="th-wrap">
						{month}
					</div>
				</th>
				{props.plan_grid_view === PLAN_GRID_VIEWS.hours ?
					getPlanHourlyHeader(props)
					:
					getPlanHeader(props)
				}
			</tr>
		</thead>
	)


}

export default PlanTableHead