import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
	FormControl,
	Dropdown,
	OverlayTrigger,
	Tooltip,
} from "react-bootstrap";
import moment from "moment";
import Moment from "react-moment";
import { toastr } from "react-redux-toastr";
import {
	FiArrowLeft,
	FiMoreHorizontal,
	FiPlus,
	FiChevronLeft,
	FiChevronRight,
	FiClock,
} from "react-icons/fi";
import { FaRegComment } from "react-icons/fa";
import { MobileOrTablet, Desktop } from "utils/responsive";
import TimeInputPolyfill from "react-time-input-polyfill";
import { isSafari, deviceType, browserName, osName } from 'react-device-detect';
import * as employeesActions from "actions/employeesActions";
import * as timesActions from "actions/timesActions";
import { timesService, plansService, calendarsService, timeTypesService, transactionsService, accountsService } from "services";
import {
	getDiffTime,
	formatMinutesToTime,
	formatSecondsToTime,
	getWorkedTime,
	getWorkedTimeRaw,
	getBalanceHours
} from "utils/datetime";
import { isAdmin, isSupervisor } from "utils/utils";
import Layout from "components/Layout";
import Loading from "components/Loading";
import Switch from "components/Switch";
import Modal from "components/Modal";
import TimeObservationEdit from "components/TimeRegistrations/TimeObservationEditModal";
import ShiftPreviewTooltip from 'components/Shifts/ShiftPreviewTooltip';
import { showModal, hideModal } from "actions/modalActions";
import { getEmployeeTimeTypes } from "components/TimeTypes/utils";

class TimeRegistrations extends Component {
	constructor(props) {
		super(props);
		// console.log(props);
		const userId = props.user.id;
		let employee_id = userId;
		let isMe = true;

		if (this.props.match.params.id !== undefined) {
			employee_id = parseInt(this.props.match.params.id);
			isMe = userId === employee_id;
		}

		const today = new Date();
		this.state = {
			employee_id,
			isMe: isMe,
			times: [],
			prevTimes: [],
			current: today,
			isCurrentMonth: true,
			loading: true,
			plan_assignments: [],
			objective_hours: [],
			planned_hours: [],
			employee_calendars: [],
			allow_manual_time_registrations: props.user.employee_with_contract && props.user.employee_with_contract.contract ? props.user.employee_with_contract.contract.allow_manual_time_registrations : true,
		};

		this.myRef = React.createRef();
		this.closeModal = this.closeModal.bind(this);
		this.openDeleteModal = this.openDeleteModal.bind(this);
		this.openAlertModal = this.openAlertModal.bind(this);
		this.openObservationsModal = this.openObservationsModal.bind(this);
		this.getTimeList = this.getTimeList.bind(this);
		this.getAssignmentsList = this.getAssignmentsList.bind(this);
		this.getCalendarList = this.getCalendarList.bind(this);
	}

	async componentDidMount() {
		if (!this.state.isMe) {
			this.props.getEmployee(this.state.employee_id);
		}
		this.props.getSelectableTimeTypes();
		this.props.getDefaultTimeType();
		await this.getEmployeeTimeTypeList();
		this.getTimeList();
		this.getAssignmentsList();
		this.getCalendarList();
		this.getObjectiveHoursList();
	}

	getEmployeeTimeTypeList = async () => {
		const { user } = this.props;
		const { isMe, employee_id } = this.state;

		var employee_time_types_list = [];

		if (isMe && user.employee_with_time_types) {
			employee_time_types_list = user.employee_with_time_types;
		}
		else {
			var response = await timeTypesService.getContractTimeTypes({ employee_id });
			employee_time_types_list = response.ok ? response : [];
		}

		this.setState({
			employee_time_types_list,
		});
	}

	closeModal() {
		this.props.hideModal();
	}

	openDeleteModal(time) {
		const endTime = time.end
			? moment.utc(time.end).local().format("HH:mm")
			: "--:--";

		var message =
			time.day_of_rest !== undefined
				? `<p>Se va a eliminar el día de descando para el <strong>${moment
					.utc(time.start)
					.local()
					.format("DD/MM/YY")}</strong></p>`
				: `<p>Se va a eliminar el siguiente tiempo:</p>
						<p><strong>${moment.utc(time.start).local().format("DD/MM")}</strong>
						de <strong>${moment
					.utc(time.start)
					.local()
					.format("HH:mm")}</strong> a <strong>${endTime}</strong></p>`;

		this.props.showModal(
			{
				open: true,
				title: "Eliminar tiempo",
				messageHTML: message,
				deleteAction: () => this.confirmDelete(time),
				closeModal: this.closeModal,
				style: { width: "300px" },
			},
			"delete"
		);
	}

	openAlertModal(message, title) {
		this.props.showModal(
			{
				open: true,
				title: title ? title : "Error al añadir tiempo",
				style: { width: "400px" },
				message: message,
				closeModal: this.closeModal,
			},
			"alert"
		);
	}

	openObservationsModal(time, titleObservations) {
		this.props.showModal(
			{
				open: true,
				title: titleObservations,
				style: { width: "400px" },
				content: (
					<TimeObservationEdit
						getTimeList={this.getTimeList}
						time={time}
						handleClose={this.closeModal}
					/>
				),
				closeModal: this.closeModal,
			},
			"edit"
		);
	}

	// delete existing time
	confirmDelete = async (time) => {
		var id = time.id;

		//if not temp
		if (id.toString().lastIndexOf("temp") < 0) {
			await timesService.remove(id);
		}

		this.getTimeList();
		this.closeModal();

		if (time.day_of_rest !== undefined) {
			toastr.success("¡Bien!", "Día de descanso eliminado correctamente");
		} else {
			toastr.success("¡Bien!", "Fichaje eliminado correctamente");
		}
	};

	// retrieve time list by employee and month
	async getTimeList() {
		const employee_id = this.state.employee_id;
		var day = this.state.current.getDate();
		const month = this.state.current.getMonth();
		const year = this.state.current.getFullYear();

		const start_from = moment(this.state.current)
			.startOf("month")
			.format("YYYY-MM-DD");
		const start_to = moment(this.state.current)
			.endOf("month")
			.format("YYYY-MM-DD");

		this.setState({
			loading: true,
		});

		let saved_times = await timesService.getAll({
			employee_id,
			start_from,
			start_to,
		});
		// console.log(saved_times);

		var times = [];

		// check if
		const isCurrent = this._checkIfCurrent();

		if (!isCurrent)
			day = moment(this.state.current).endOf("month").format("DD");

		// adding empty days
		for (var i = 1; i <= day; i++) {
			var d = new Date(year, month, i);

			let dia = {
				id: "temp_" + i,
				day: d,
				start: null,
				end: null,
				// type: this.props.default_time_type,
				assignments: []
			};

			var existing_times = this._timeFilter(saved_times.data, d, i);

			if (existing_times.length > 0)
				times = [...times, ...existing_times];
			else {
				// si el timetype por defecto está entre los permitidos, lo mostramos, sino no
				const time_type = this.getEmployeeDefaultTimeType(d);
				if (time_type)
					dia.type = time_type;

				times.push(dia);
			}
		}

		// order
		times = this._timeSort(times);
		// console.log(times);

		this.setState(
			{
				times: times,
				prevTimes: times,
				loading: false,
				isCurrentMonth: isCurrent,
			},
			() => {
				if (this.state.isCurrentMonth) {
					this.myRef.scrollIntoView({ block: "start", behavior: "smooth" });
				}
			}
		);
	}

	// retrieve assignments list by employee and month
	async getAssignmentsList() {
		const employee_id = this.state.employee_id;
		const start_from = moment(this.state.current)
			.startOf("month")
			.format("YYYY-MM-DD");
		const start_to = moment(this.state.current)
			.endOf("month")
			.format("YYYY-MM-DD");

		const response = await plansService.getPlanAssignments({
			employee_id,
			start: start_from,
			end: start_to,
		});

		// console.log(response);
		const plan_assignments = response.data;
		// console.log(plan_assignments);

		this.setState(
			{
				plan_assignments: !plan_assignments || plan_assignments.length < 1 || plan_assignments[0].plans.length < 1 ? [] : plan_assignments[0].plans,
			}
		);
	}

	// get objective hours list by employee and month
	async getObjectiveHoursList() {
		var { employee_id, account_objetivo } = this.state;

		if (!account_objetivo) {
			account_objetivo = await accountsService.getAll("Horas objetivo"); // guarrofix
		}

		const account_objetivo_id = (account_objetivo.data.length > 0) ? account_objetivo.data[0].id : -1;

		const startDate = moment(this.state.current)
			.startOf("month")
			.format("YYYY-MM-DD");
		const endDate = moment(this.state.current)
			.endOf("month")
			.format("YYYY-MM-DD");


		// fetch horas objetivo
		const response_objective = await transactionsService.getAll(employee_id, account_objetivo_id, startDate, endDate, "", 0, "+day");
		const response_planned = await plansService.getPlannedHours({ employee_id, start: startDate, end: endDate });
		// console.log(employee_id);
		// console.log(response_objective);
		// console.log(response_planned);

		this.setState(
			{
				account_objetivo,
				objective_hours: response_objective.data,
				planned_hours: response_planned.ok ? response_planned : [],
			}
		);
	}


	// retrieve calendar list by employee and month
	async getCalendarList() {
		const employee_id = this.state.employee_id;
		const start_from = moment(this.state.current)
			.startOf("month")
			.format("YYYY-MM-DD");
		const start_to = moment(this.state.current)
			.endOf("month")
			.format("YYYY-MM-DD");

		this.setState({
			loading: true,
		});

		const response = await calendarsService.getCalendarsByEmployee({
			employee_id,
			from: start_from,
			to: start_to,
		});

		// console.log(response);
		const employee_calendars = response;

		this.setState(
			{
				employee_calendars: !employee_calendars.ok || employee_calendars.length < 1 ? [] : employee_calendars
			}
		);
	}

	_timeFilter = (list, d, i) => {
		return list.filter((time) => {
			let dia = moment.utc(time.start).local().format();
			let numdia = moment.utc(time.start).local().format("D");

			time.day = dia;
			return parseInt(numdia) === i;
		});
	};

	// time sorting from 1 to 30
	_timeSort = (list) => {
		return list.sort(function (a, b) {
			a = new Date(a.day);
			b = new Date(b.day);
			// return a>b ? -1 : a<b ? 1 : 0; // recent -> vell
			return a < b ? -1 : a > b ? 1 : 0; // vell -> recent
		});
	};

	// check if active month is current month
	_checkIfCurrent = () => {
		const today = new Date();
		const isCurrent =
			this.state.current.getFullYear() === today.getFullYear() &&
			this.state.current.getMonth() === today.getMonth();

		return isCurrent;
	};

	// back click - > history back
	handleBackClick = () => {
		this.props.history.goBack();
	};

	// updating active month +/- 1
	handleChangeMonth = (sum) => {
		const current = this.state.current;
		const newCurrent = moment(current).add(sum, 'months');

		this.setState(
			{
				current: newCurrent.toDate(),
			},
			() => {
				this.getTimeList();
				this.getAssignmentsList();
				this.getObjectiveHoursList();
				this.getCalendarList();
			}
		);
	};

	// switch approval state
	handleTimeApproval = async (event, time) => {
		const { times } = this.state;
		const isApproved = event.target.checked;
		var approvalList = [];

		// envío de un solo fichaje para aprobación (una mierda de momento)
		// const updatedTimes = times.map((item) => {
		// 	if (item.id === time.id) {
		// 		item.approved = isApproved;

		// 		let temp = { id: item.id, approved: isApproved };
		// 		approvalList.push(temp);
		// 	}
		// 	return item;
		// });

		// aprueba fichajes del día
		const start = time.day;
		const updatedTimes = times.map((item) => {
			const date1 = new Date(item.day).getDate();
			const date2 = new Date(start).getDate();

			if (
				date1 === date2 &&
				((item.start !== null && item.end !== null) || item.day_of_rest)
			) {
				item.approved = isApproved;

				let temp = { id: item.id, approved: isApproved };
				approvalList.push(temp);
			}
			return item;
		});

		this.handleApproval(updatedTimes, approvalList);
	};

	handleMonthApproval = async () => {
		const { times } = this.state;
		var approvalList = [];

		const updatedTimes = times.map((item) => {
			if (
				(item.start !== null && item.end !== null && item.end !== undefined)
			) {
				// console.log(item);
				if (!item.approved) {
					item.approved = true;

					let temp = { id: item.id, approved: true };
					approvalList.push(temp);
				}

			}
			return item;
		});

		this.handleApproval(updatedTimes, approvalList);
	};


	handleApproval = async (updatedTimes, approvalList) => {
		const response = await timesService.approve(approvalList);

		if (response.ok) {
			this.setState({
				times: updatedTimes,
			});
		}
		else {
			// show error
			let textError = response.errors[0].description;

			this.openAlertModal(
				textError,
				"Error al aprobar fichajes"
			);
		}
	};

	getDeviceLabel = (deviceType) => {
		switch (deviceType) {
			case 'browser':
				return 'PC';
			case 'mobile':
				return 'Móvil';
			case 'tablet':
				return 'Tablet';
			default:
				return deviceType.charAt(0).toUpperCase() + deviceType.slice(1);
		}
	}

	handleChange = (rawValue, element, time) => {
		let id = time.id;
		let day = moment(time.day); // Usar moment para manejar la fecha
		let key = element.id.replace("_" + id, "");

		let value = "";

		if (rawValue !== "" && rawValue.includes(":")) {
			let [hours, minutes] = rawValue.split(":").map(Number);
			let valueMoment = day.clone().set({ hour: hours, minute: minutes, second: 0 });

			if (key === "end") {
				let startMoment = moment(time.start);

				// Si 'end' es antes que 'start' y están en el mismo día
				if (valueMoment.isBefore(startMoment)) {
					if (
						valueMoment.hours() === startMoment.hours() &&
						valueMoment.minutes() === startMoment.minutes() &&
						valueMoment.seconds() < startMoment.seconds()
					) {
						// Solo incrementa un segundo
						valueMoment = startMoment.clone().add(1, "second");
					} else {
						// Si realmente es antes (en horas/minutos), suma un día
						valueMoment.add(1, "day");
					}
				}
			}

			// Ajusta para el DST automáticamente al usar moment
			value = valueMoment.toISOString();

			// Asigna el valor ajustado al estado, junto con información del dispositivo
			let device_type = this.getDeviceLabel(deviceType); // Asegúrate de definir deviceType, osName, y browserName

			this.setState((prevState, props) => {
				return {
					prevTimes: prevState.times,
					times: prevState.times.map((t) =>
						t.id === id ? { ...t, [key]: value, [key + "_origin"]: "[" + device_type + "] " + osName + ", " + browserName } : t
					),
				};
			});
		} else {
			// Borra el valor si no es una hora válida
			if (key === "end") {
				this.setState((prevState, props) => {
					return {
						prevTimes: prevState.times,
						times: prevState.times.map((t) =>
							t.id === id ? { ...t, [key]: null } : t
						),
					};
				});
			}
		}
	};


	// handle time type on select change
	handleSelectType = (event, id) => {
		const { times } = this.state;
		const timeTypeId = parseInt(event.target.value);
		const type = this.props.select_time_types.find((t) => t.id === timeTypeId);

		const updatedTimes = times.map((item) => {
			if (item.id === id) {
				item.type = type;
				this.saveTime(id, item);
			}
			return item;
		});

		this.setState({
			prevTimes: times,
			times: updatedTimes,
		});
	};

	// handle input blur -- > save time
	handleBlur = (id) => {
		const hasChanged = this.state.times !== this.state.prevTimes;
		// console.log(this.state.times);
		// console.log(this.state.prevTimes);

		if (hasChanged) {
			const state_time = this.state.times.filter((t) => t.id === id);
			//console.log(state_time[0]);
			this.saveTime(id, state_time[0]);
		}
	};

	// add new time or update existing one
	saveTime = async (id, state_time) => {
		// console.log(state_time);


		if (state_time.start === null || state_time.start === "") {
			// do nothing
		} else {
			if (state_time === undefined)
				state_time = this.state.times.filter((t) => t.id === id)[0];

			let start_utc = null;
			let end_utc = null;

			if (state_time.start) start_utc = moment.utc(state_time.start).format();

			if (state_time.end) end_utc = moment.utc(state_time.end).format();

			const isValidDate =
				moment(state_time.start).isValid() && moment(state_time.end).isValid();

			let approved = false;
			if (
				state_time.approved !== undefined &&
				state_time.approved &&
				isValidDate
			)
				approved = true;

			const time = {
				start: start_utc,
				end: end_utc,
				approved: approved,
				type_id: state_time.type ? state_time.type.id : -1,
				start_origin: state_time.start_origin,
				end_origin: state_time.end_origin,
				employee_id: this.state.employee_id,
			};

			var update_assignments = false;

			// if not "temp" -> update existing
			if (id.toString().lastIndexOf("temp") < 0) {
				const response = await timesService.update(id, time);
				// console.log(response);
				if (!response.ok) {
					let textError = response.errors[0].description;
					this.openAlertModal(textError);

					// gets old saved version
					const fetch_time = await timesService.getById(id);
					fetch_time.day = fetch_time.start;

					this.setState({
						times: this.state.times.map((t) => (t.id === id ? fetch_time : t)),
					});
				} else {
					this.updateTimeView(id, response);
					update_assignments = true;
				}
			} else {
				// if "temp" -> add new time
				const response = await timesService.add(time);
				// update list so the time id gets updated
				if (!response.ok) {
					let textError = response.errors[0].description;
					this.openAlertModal(textError);

					const reset_time = {
						id: id,
						day: state_time.day,
						start: null,
						end: null,
						approved: false,
						// type: this.props.default_time_type,
					};

					// si el timetype por defecto está entre los permitidos, lo mostramos, sino no
					const time_type = this.getEmployeeDefaultTimeType(state_time.day);
					if (time_type)
						reset_time.type = time_type;

					this.setState({
						times: this.state.times.map((t) => (t.id === id ? reset_time : t)),
					});
				} else {
					this.updateTimeView(id, response);
					update_assignments = true;
				}
			}

			const day_assignments = this.getDayAssignments(time.start);

			// Si el fichaje se ha creado ok y se crea turno actualizamos lista de assignments
			if (update_assignments && (day_assignments.length === 0 || (day_assignments.length === 1 && day_assignments[0].shift.type !== "Trabajo"))) {
				this.getAssignmentsList();
				this.getObjectiveHoursList();
			}
		}
	};

	// update time record if response object is different (= time correction)
	updateTimeView = (id, obj) => {
		const { times } = this.state;
		var index = times.findIndex((x) => x.id === id);
		const isSame =
			moment(obj.start).isSame(times[index].start) &&
			(!moment(times[index].end).isValid() ||
				moment(obj.end).isSame(times[index].end));
		times[index].id = obj.id;
		times[index].start = obj.start;
		times[index].end = obj.end;

		this.setState({
			times,
		});

		if (isSame) {
			toastr.success("¡Bien!", "Cambios realizados correctamente");
		} else {
			toastr.warning("Corrección", "Se ha aplicado una corrección de tiempo");
		}
	};

	getEmployeeDefaultTimeType = (date) => {
		const { select_time_types, default_time_type } = this.props;
		const { employee_time_types_list } = this.state;

		const time_types_list = employee_time_types_list.length > 0 ? getEmployeeTimeTypes(employee_time_types_list, date) : select_time_types;
		const time_type = time_types_list.find(tt => tt.id === default_time_type.id);

		return time_type;
	};

	// add time in list (without saving)
	handleAddTime = (event, date) => {
		var times = this.state.times;
		let conta = times.length + 1;

		let dia = {
			id: "temp_" + conta,
			day: moment(date).toDate(),
			start: null,
			end: null,
			// type: this.props.default_time_type,
		};

		// si el timetype por defecto está entre los permitidos, lo mostramos, sino no
		const time_type = this.getEmployeeDefaultTimeType(date);
		if (time_type)
			dia.type = time_type;

		times = times.concat(dia);
		times = this._timeSort(times);

		this.setState({
			times,
		});
	};

	handleAddRestDay = async (time) => {
		const newRestTime = {
			day_of_rest: moment(time.day).format("YYYY-MM-DD"),
			approved: false,
		};

		const response = await timesService.add(newRestTime);

		newRestTime.id = time.id;

		if (response.ok) {
			this.setState({
				times: this.state.times.map((t) =>
					t.id === time.id ? newRestTime : t
				),
			});

			toastr.success("¡Bien!", "Día de descanso añadido correctamente");
		} else {
			let textError = response.errors[0].description;
			this.openAlertModal(textError, "Error al añadir descanso");
		}
	};

	// renders content
	getContent = () => {
		const { isMe } = this.state;

		if (this.state.loading) {
			return <Loading />;
		}

		if (!isMe && !this.props.employee) {
			return "No se han podido cargar los fichajes";
		}

		const { employee } = this.props;
		const total = getWorkedTime(this.state.times);
		const title = isMe ? "Mis fichajes" : employee.name + " " + employee.surname;


		return (
			<>
				<div className="heading">
					<h1 className="title">
						{(isAdmin() || isSupervisor()) && (
							<FiArrowLeft
								className="button-icon"
								onClick={this.handleBackClick}
							/>
						)}
						{title}
					</h1>
					<div className="heading-right">
						{(isAdmin() || isSupervisor()) && (
							<button
								type="button"
								onClick={this.handleMonthApproval}
								className="btn btn-outline-primary btn-aprobar"
							>
								Aprobar fichajes pendientes <Moment format="MMMM">{this.state.current}</Moment>
							</button>
						)}
						<div className="employee-data">
							<h3>
								<button
									className="btn-transparent calendar-left"
									onClick={() => this.handleChangeMonth(-1)}
								>
									<FiChevronLeft />
								</button>
								<Moment format="MMMM YYYY">{this.state.current}</Moment>

								{!this._checkIfCurrent() && (
									<button
										className="btn-transparent calendar-right"
										onClick={() => this.handleChangeMonth(1)}
									>
										<FiChevronRight />
									</button>
								)}
							</h3>
							<span className="summary">
								<FiClock className="summary-icon" />{" "}
								<span className="summary-text">total trabajado</span>{" "}
								<strong>{total}</strong>
							</span>
						</div>
					</div>
				</div>

				<MobileOrTablet>
					<table className="table table-time-registrations table-mobile">
						<tbody>
							{this.state.times.length === 0
								? this.getErrorRow()
								: this.getRows()}
						</tbody>
					</table>
				</MobileOrTablet>
				<Desktop>
					<table className="table table-time-registrations table-zebra">
						<thead>
							<tr>
								<th>Día</th>
								<th>Fichajes</th>
								<th></th>
								<th></th>
								<th></th>
								<th className="aright">Tiempo</th>
								<th className="aright">Total trabajado</th>
								<th className="aright">Balance</th>
								<th className="acenter">Turnos</th>
								<th className="acenter">Aprobado</th>
								<th className="td-actions">
									<FiMoreHorizontal />
								</th>
							</tr>
						</thead>
						<tbody>
							{this.state.times.length === 0
								? this.getErrorRow()
								: this.getRows()}
						</tbody>
					</table>
				</Desktop>
			</>
		);
	};

	getErrorRow = () => {
		return <p>No se han podido cargar los tiempos</p>;
	};

	getActionsMenu = (time) => {
		var titleObservations = time.observations
			? "Editar observaciones"
			: "Añadir observaciones";

		return (
			<Dropdown>
				<Dropdown.Toggle variant="action">
					<FiMoreHorizontal />
				</Dropdown.Toggle>
				<Dropdown.Menu alignRight style={{ margin: 0 }}>
					<Dropdown.Item
						as="button"
						className="option-observations"
						onClick={() => this.openObservationsModal(time, titleObservations)}
					>
						{titleObservations}
					</Dropdown.Item>
					<Dropdown.Item
						as="button"
						className="option-add-time"
						onClick={(e) => this.handleAddTime(e, time.day)}
					>
						Añadir tiempo
					</Dropdown.Item>
					<Dropdown.Item
						className="option-delete"
						as="button"
						onClick={() => this.openDeleteModal(time)}
					>
						Eliminar fichaje
					</Dropdown.Item>
					<Dropdown.Item
						className="option-rest"
						as="button"
						onClick={() => this.handleAddRestDay(time)}
					>
						Este día descanso
					</Dropdown.Item>

					<Dropdown.Item
						className="option-remove-rest"
						as="button"
						onClick={() => this.openDeleteModal(time)}
					>
						Eliminar día de descanso
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		);
	};

	getTimeInputs = (time, i, disabled) => {
		// fix de input time para safari
		const isModernIOS = isSafari && /OS (1[6-9]|[2-9][0-9])_/.test(navigator.userAgent);

		const isEmptyStartSafari = isSafari && (time.start === null || time.start === undefined);
		const isEmptyEndSafari = isSafari && (time.end === null || time.end === undefined);
		const time_start = isEmptyStartSafari ? (isModernIOS ? "--:--" : "00:00") : moment.utc(time.start).local().format("HH:mm");
		const time_end = isEmptyEndSafari ? (isModernIOS ? "--:--" : "00:00") : moment.utc(time.end).local().format("HH:mm");

		return (
			<form className="td-flex nowrap" noValidate>
				<span className="time-container">
					<TimeInputPolyfill
						type="text"
						id={`start_${time.id}`}
						key={`${i}_1`}
						disabled={disabled}
						className={`form-time ${isEmptyStartSafari ? "is-safari is-empty" : ""}`}
						value={
							(time.start === null || time.start === undefined) && !isSafari
								? ""
								: time_start
						}
						onChange={({ value, element }) =>
							this.handleChange(value, element, time)
						}
						onBlur={() => this.handleBlur(time.id)}
						required
					/>
				</span>
				<span>a</span>
				<span className="time-container">
					<TimeInputPolyfill
						id={`end_${time.id}`}
						key={`${i}_2`}
						disabled={disabled}
						type="text"
						className={`form-time ${isEmptyEndSafari ? "is-safari is-empty" : ""}`}
						value={
							(time.end === null || time.end === undefined) && !isSafari
								? ""
								: time_end
						}
						onChange={({ value, element }) =>
							this.handleChange(value, element, time)
						}
						onBlur={() => this.handleBlur(time.id)}
						required
					/>
				</span>
			</form>
		);
	};

	getTimeType = (time, disabled) => {
		const { select_time_types } = this.props;
		const { employee_time_types_list } = this.state;

		const time_types_list = employee_time_types_list.length > 0 ? getEmployeeTimeTypes(employee_time_types_list, time.day) : select_time_types;

		let typesList = time_types_list.length > 0 &&
			time_types_list.map((item, i) => {
				return (
					<option key={i} value={item.id}>
						{item.name}
					</option>
				);
			}, this);

		return (
			<FormControl
				id={`type_${time.id}`}
				disabled={disabled}
				className="form-md select-type"
				as="select"
				value={time.type && time.type.id}
				onChange={(e) => this.handleSelectType(e, time.id)}
			>
				<option value="-1">Seleccionar tipo</option>
				{typesList}
			</FormControl>
		);
	};

	getObservations = (time) => {
		var titleObservations = time.observations
			? "Editar observaciones"
			: "Añadir observaciones";

		return (
			<OverlayTrigger
				placement="right"
				overlay={<Tooltip>{time.observations}</Tooltip>}
			>
				<FaRegComment
					className="icon-comment"
					onClick={() => this.openObservationsModal(time, titleObservations)}
				/>
			</OverlayTrigger>
		);
	};

	getDayAssignments = (day) => {
		const { plan_assignments } = this.state;
		const filtered_assignments = plan_assignments.filter((d) => {

			// let selected_day = moment.utc(day).local().format("D");
			// let d_day = moment.utc(d.day).local().format("D");

			let selected_day = moment(day).format("D");
			let d_day = moment(d.day).format("D");

			// console.log("****");
			// console.log(moment(day).format("YYYY-MM-DD"));
			// console.log(d.day);
			// console.log(selected_day);
			// console.log(d_day);

			return selected_day === d_day;
		});

		const day_assignments = filtered_assignments[0] ? filtered_assignments[0].assignments : [];
		// console.log(day);

		// console.log(day_assignments);

		return day_assignments;
	}

	getShiftList = (day) => {
		const day_assignments = this.getDayAssignments(day);
		// console.log(day_assignments);

		if (day_assignments === undefined || day_assignments.length < 1)
			return "";

		return (
			<ul className="shift-list-items">
				{this.getAssignedShifts(day_assignments)}
			</ul>
		);
	};


	getAssignedShifts = (day_assignments) => day_assignments.map((item) => {
		const shift = item.shift;
		const shiftname = shift.description ? "(" + shift.name + ") " + shift.description : shift.name;
		const time_intervals = item.time_intervals || [];
		const tooltip = <Tooltip className="tooltip-shift"><ShiftPreviewTooltip shift={shift} time_intervals={item.time_intervals} /></Tooltip>;

		return (
			<OverlayTrigger key={shift.id} overlay={tooltip}>
				<li
					title={`${shiftname}`}
					className="shift-item"
					style={{
						backgroundColor: shift.background_color && shift.background_color,
						borderColor: shift.border_color && shift.border_color,
					}}
				>
					{shift.name.substring(0, 3)}
				</li>
			</OverlayTrigger>
		);
	});

	// DAY TYPES
	getDayType = (day) => {
		const { employee_calendars } = this.state;
		const filtered_calendar = employee_calendars.filter((d) => {
			let selected_day = moment(day).format("D");
			let d_day = moment(d.day).format("D");

			return selected_day === d_day;
		});

		// console.log(filtered_calendar);

		return filtered_calendar[0] ? filtered_calendar[0].day_type : {};
	};


	getBalanceByDay = (day, list) => {
		const filtered_hours = list.filter((d) => {
			let selected_day = moment(day).format("D");
			let d_day = moment(d.day).format("D");

			return selected_day === d_day;
		});

		// console.log(filtered_hours);
		const suma = filtered_hours.reduce((acc, numero) => acc + numero.value, 0);

		var return_value = 0;
		if (filtered_hours.length === 1)
			return_value = filtered_hours[0].value;
		else if (filtered_hours.length > 1)
			return_value = suma;


		const formattedValue = new Intl.NumberFormat("de-DE", { maximumSignificantDigits: 2 }).format(return_value);
		return formattedValue;
	};

	// time row rendering
	getRows = () =>
		this.state.times.map((time, i) => {
			var isMain = true;
			const { isMe, isCurrentMonth, allow_manual_time_registrations, objective_hours, planned_hours } = this.state;
			const balance_calcularion = this.props.user.time_registration_balance_calculation;
			var disabled = time.approved;

			if ((!isMe && !isAdmin() && !isSupervisor()) || !allow_manual_time_registrations) {
				disabled = true;
			}

			const start = new Date(time.start);
			const end = new Date(time.end);
			var minutes = getDiffTime(start, end);
			if (minutes < 0) minutes = 0;
			const hours = formatMinutesToTime(minutes);

			if (i > 0) {
				const prev = new Date(this.state.times[i - 1].day).getDate();
				const now = new Date(time.day).getDate();
				if (prev === now)
					isMain = false;
			}

			// if is main row, calculate total working minutes
			var total = -1;
			var balance = 0;
			var balanceTitle = "";
			var tooltipInfo = "";
			var sTipoTiempo = "";

			if (isMain) {
				var total_raw = getWorkedTimeRaw({ times: this.state.times, date: time.start, unit: "seconds" });
				total = formatSecondsToTime(total_raw, "hm");

				const objective_hours_day = this.getBalanceByDay(time.day, objective_hours);
				const planned_hours_day = this.getBalanceByDay(time.day, planned_hours);

				// console.log(objective_hours_day);
				// console.log(planned_hours_day);

				if (minutes > 0) {
					if (balance_calcularion === "TARGET_HOURS") {
						// horas objetivo
						balance = getBalanceHours(objective_hours_day, total_raw);
						sTipoTiempo = " horas objetivo";
					}
					else {
						// horas planificadas
						balance = getBalanceHours(planned_hours_day, total_raw);
						sTipoTiempo = " horas planificadas";
					}

					if (balance.short_raw > 0)
						balanceTitle = <p className="tooltip-title">Tienes un exceso de jornada de <strong>{balance.formated}</strong> según las {sTipoTiempo}</p>

					if (balance.short_raw < 0)
						balanceTitle = <p className="tooltip-title">Te faltan <strong>{balance.formated}</strong> para finalizar la jornada según las{sTipoTiempo}</p>

					tooltipInfo = <Tooltip className="tooltip-balance">
						<p>Horas objetivo: <strong>{objective_hours_day}h</strong></p>
						<p>Horas planificadas: <strong>{planned_hours_day}h</strong></p>
						{balanceTitle}
					</Tooltip>;
				}
			}

			const today = new Date();
			const isToday =
				isCurrentMonth && new Date(time.day).getDate() === today.getDate();

			const isRestDay =
				time.day_of_rest !== undefined && time.day_of_rest !== null;

			const day_type = this.getDayType(time.day);
			// console.log(day_type);

			const day_cell_content = (<>
				<Moment className="tag-dia-setmana" format="dd">
					{time.day}
				</Moment>
				<Moment className="tag-dia" format="D MMMM">
					{time.day}
				</Moment>
			</>
			);



			return (
				<Fragment key={i}>
					<MobileOrTablet>
						{isMain && (
							<tr
								ref={(ref) => (this.myRef = ref)}
								className={`${time.start !== null ? "filled" : "empty"}  ${isToday ? "today" : ""
									} ${isRestDay ? "rest-day" : ""} tr-head`}
							>
								<td colSpan="7">
									<div className="td-flex">
										{day_type ? (
											<OverlayTrigger
												placement="right"
												overlay={<Tooltip>{day_type.name} - {day_type.description}</Tooltip>}
											>
												<span className="tag-dia-container day-type" style={{
													backgroundColor: day_type.color && day_type.color,
													paddingLeft: day_type.color && '5px',
													paddingRight: day_type.color && '5px',
												}}>
													{day_cell_content}
												</span>
											</OverlayTrigger>)
											:
											(
												<span className="tag-dia-container">
													{day_cell_content}
												</span>
											)}

										<span className="total">
											{total !== -1 && minutes !== 0 ? total : "-"}
										</span>
										<span className={`balance ${balance.short_raw > 0 ? "color-green-dark" : ""}  ${balance.short_raw < 0 ? "color-red" : ""}`}>
											{balance.short}
										</span>
									</div>
								</td>
							</tr>
						)
						}

						<tr
							className={`${time.start !== null ? "filled" : "empty"} ${isMain ? "main" : "repetida"
								}  ${isToday ? "today" : ""} ${disabled ? "disabled" : ""} ${isRestDay ? "rest-day" : ""
								} tr-body`}
						>
							{isRestDay ? (
								<>
									<td colSpan="5" className="d-sm-none col-descanso">
										Este día descanso
									</td>
									<td
										colSpan="4"
										className="d-none d-sm-table-cell col-descanso"
									>
										Este día descanso
									</td>
								</>
							) : (
								<>
									<td>{this.getTimeInputs(time, i, disabled)}</td>

									<td colSpan="4" className="d-sm-none">
										{this.getTimeType(time, disabled)}
									</td>
									<td className="d-none d-sm-table-cell">
										{this.getTimeType(time, disabled)}
									</td>

									<td className="d-none d-sm-table-cell">
										{time.observations && this.getObservations(time)}
									</td>

									<td className="aright nowrap d-none d-sm-table-cell">
										{hours}
									</td>
								</>
							)}

							<td>
								{isMain && this.getShiftList(time.day)}
							</td>

							<td className="acenter d-none d-sm-table-cell">
								{(isAdmin() || isSupervisor()) ? (
									<Switch
										controlId={`approved_${time.id}`}
										isOn={disabled}
										handleToggle={(e) => this.handleTimeApproval(e, time)}
									/>
								) : (
									[
										time.approved ? (
											<span
												title="SI"
												key="1"
												className="bolita bolita-true"
											></span>
										) : (
											<span
												title="NO"
												key="2"
												className="bolita bolita-false"
											></span>
										),
									]
								)}
							</td>

							<td className="td-actions">
								{!disabled && this.getActionsMenu(time)}
							</td>
						</tr>
					</MobileOrTablet >

					<Desktop>
						<tr
							ref={(ref) => (this.myRef = ref)}
							className={`${time.start !== null ? "filled" : "empty"} ${isMain ? "main" : "repetida"
								}  ${isToday ? "today" : ""} ${isRestDay ? "rest-day" : ""} ${disabled ? "disabled" : ""
								}`}
						>

							{day_type ?
								(<td className="day-type" style={{
									backgroundColor: day_type.color && day_type.color,
								}}>
									<OverlayTrigger
										placement="right"
										overlay={<Tooltip>{day_type.name} - {day_type.description}</Tooltip>}
									>
										<span className="tag-dia-container">
											{day_cell_content}
										</span>
									</OverlayTrigger>
								</td>) :
								(
									<td>
										<span className="tag-dia-container">
											{day_cell_content}
										</span>
									</td>
								)}

							{isRestDay ? (
								<td colSpan="7" className="col-descanso">
									Este día descanso
								</td>
							) : (
								<>
									<td>{this.getTimeInputs(time, i, disabled)}</td>
									<td>{this.getTimeType(time, disabled)}</td>
									<td>
										<button
											type="button"
											title="Añadir tiempo"
											onClick={(e) => this.handleAddTime(e, time.day)}
											className="btn btn-new btn-new-small"
										>
											<FiPlus />
										</button>
									</td>
									<td>{time.observations && this.getObservations(time)}</td>

									<td className="aright">{hours}</td>

									<td className="col-total aright">
										{total !== -1 && minutes !== 0 ? total : "-"}
									</td>
									<td className="col-balance aright">
										<OverlayTrigger
											overlay={tooltipInfo}
										>
											<span className={`${balance.short_raw > 0 ? "color-green-dark" : ""}  ${balance.short_raw < 0 ? "color-red" : ""}`}>
												{balance.short}
											</span>
										</OverlayTrigger>
									</td>
								</>
							)}

							<td>
								{isMain && this.getShiftList(time.day)}
							</td>

							<td className="acenter">
								{(isAdmin() || isSupervisor()) ? (
									<Switch
										controlId={`approved_${time.id}`}
										isOn={time.approved}
										handleToggle={(e) => this.handleTimeApproval(e, time)}
									/>
								) : (
									[
										time.approved ? (
											<span
												title="SI"
												key="1"
												className="bolita bolita-true"
											></span>
										) : (
											<span
												title="NO"
												key="2"
												className="bolita bolita-false"
											></span>
										),
									]
								)}
							</td>

							<td>{!disabled && this.getActionsMenu(time)}</td>
						</tr>
					</Desktop>
				</Fragment >
			);
		});

	render() {
		return (
			<>
				<Layout className="page-time-registrations" context="times">
					{this.getContent()}
				</Layout>

				<Modal hideModal={this.props.hideModal} />
			</>
		);
	}
}

const mapStateToProps = (reducers) => {
	const r = {
		...reducers.timesReducer,
		...reducers.employeesReducer,
		user: reducers.authReducer.user,
	};
	return r;
};

const mapDispatchToProps = (dispatch) => ({
	hideModal: () => dispatch(hideModal()),
	showModal: (modalProps, modalType) => {
		dispatch(showModal({ modalProps, modalType }));
	},
	getEmployee: (id) => dispatch(employeesActions.getEmployee(id)),
	getSelectableTimeTypes: () => dispatch(timesActions.getSelectableTimeTypes()),
	getDefaultTimeType: () => dispatch(timesActions.getDefaultTimeType()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TimeRegistrations);