// ShiftHoverMenu.jsx
import React, { useState, useRef } from 'react';
import { FiPlus, FiTrash2 } from 'react-icons/fi';
import { RiPencilFill } from "react-icons/ri";
import { Overlay, Popover } from "react-bootstrap";
import ShiftSelector from 'components/Shifts/ShiftSelector';

const PlanShiftHoverMenu = ({ plan, onAdd, onEdit, onDelete }) => {

	const { shift_plan } = plan;
	const isEdit = shift_plan && shift_plan.shift && (shift_plan.shift.type === "Trabajo" || shift_plan.shift.type === "Attendance" || shift_plan.shift.allow_hourly_absence_request);


	// Estado para controlar la visibilidad del popover
	const [show, setShow] = useState(false);
	const addButtonRef = useRef(null);

	// Función que cierra el popover; se la pasamos a ShiftSelector
	const handleClose = () => setShow(false);

	// Función que alterna la visibilidad del popover
	const handleToggle = () => setShow(!show);

	// Definimos el popover, que contiene el selector de turnos.
	// Nota: aquí usamos onMouseEnter/onMouseLeave para mantenerlo abierto
	// mientras el cursor esté sobre el popover.
	const popover = (
		<Popover
			className="shift-selector-popover"
			onMouseEnter={() => setShow(true)}
			onMouseLeave={() => setShow(false)}
		>
			<Popover.Title as="h3">Selecciona un turno</Popover.Title>
			<Popover.Content>
				<ShiftSelector
					plan={plan}
					handleClose={handleClose}
					onAdd={onAdd}
				// Otras props que necesites pasar
				/>
			</Popover.Content>
		</Popover>
	);


	return (
		<div className="shift-hover-menu-container">
			<div className="shift-hover-menu">
				{/* Botón Añadir: al hacer clic se muestra el popover */}
				<button
					ref={addButtonRef}
					className="btn-hover-action btn-add"
					onClick={(e) => {
						e.stopPropagation();
						handleToggle();
					}}
					title="Añadir turno"
				>
					<FiPlus />
				</button>

				<Overlay
					target={addButtonRef.current}
					show={show}
					placement="bottom"
					containerPadding={10}
					onHide={handleClose}
				>
					{popover}
				</Overlay>

				{/* Botones de editar y eliminar */}
				{isEdit && (
					<>
						<button
							className="btn-hover-action btn-edit"
							onClick={(e) => {
								e.stopPropagation();
								onEdit(plan);
							}}
							title="Editar turno"
						>
							<RiPencilFill />
						</button>
						<button
							className="btn-hover-action btn-delete"
							onClick={(e) => {
								e.stopPropagation();
								onDelete(plan);
							}}
							title="Quitar turno"
						>
							<FiTrash2 />
						</button>
					</>
				)}
			</div>
		</div>
	);
};

export default PlanShiftHoverMenu;

