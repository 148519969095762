import React from 'react'
import Moment from 'react-moment';
import { getDiffDays } from "utils/datetime";
import { FiCalendar } from "react-icons/fi";
import { MdAttachFile } from "react-icons/md";
import { workflowsService } from "services";
const AbsenceListItemPeriod = ({ absence }) => {
	// console.log(absence);



	let nTime = absence.effective_days;
	if (nTime === 0)
		nTime = getDiffDays(absence.start, absence.end);

	let sTime = "días";
	if (nTime === 1)
		sTime = "día"


	if (absence.workflow_hourly_requested) {
		nTime = absence.duration;

		sTime = "horas";
		if (nTime === 1)
			sTime = "hora"


		nTime = nTime.toString().replace(".", ",")
	}

	const getAttachment = async (absence, attachment) => {
		// console.log(attachment);
		const response = await workflowsService.getAttachment(
			absence.id,
			attachment.url
		);
		// console.log(response);
		var url = window.URL.createObjectURL(response);
		var a = document.createElement("a");
		document.body.appendChild(a);
		a.href = url;
		a.download = attachment.name;
		a.click();
	};

	const attachments =
		absence.attachments &&
		absence.attachments.map((attachment) => {
			var size = attachment.size / 1000000;
			var sizeLetters = "Mb";

			if (size < 1) {
				size = attachment.size / 1000;
				sizeLetters = "Kb";
			}

			return (
				<li className="file-item" key={attachment.url}>
					<span
						className="file-info"
						title={attachment.name}
						onClick={() => getAttachment(absence, attachment)}
					>
						<span className="file-name">{attachment.name}</span>
						<span className="file-size">
							({size.toFixed(2)}
							{sizeLetters})
						</span>
					</span>
				</li>
			);
		});

	return (
		<>
			<div className="form-group no-form">
				<h4 className="ausencia-tipo">{absence.shift_type}</h4>
				<p className="ausencia-resumen">Petición de <span>{nTime}</span> {sTime}</p>
			</div>

			<div className="form-group no-form form-group-period flex-between align-items-start">
				<div>
					<FiCalendar />
					<span>
						<Moment className="tag-dia" format="D [de] MMM">{absence.start}</Moment>
						{absence.end &&
							<Moment className="tag-dia" format="D [de] MMM">{absence.end}</Moment>
						}
					</span>
				</div>
				{(absence.attachments && absence.attachments.length > 0) &&
					<div className='ausencia-attachments'>
						<MdAttachFile title="Tiene adjuntos" className="absence-attachment" />
						<ul className="dropzone-files">
							{attachments}
						</ul>
					</div>}
			</div>
		</>
	)
}

export default AbsenceListItemPeriod