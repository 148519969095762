// ShiftSelector.jsx
import React from "react";
import Autosuggest from "react-autosuggest";
import { toastr } from "react-redux-toastr";
import { connect } from "react-redux";
import { FormGroup, FormLabel } from "react-bootstrap";
import { FiSearch } from "react-icons/fi";
import * as shiftsActions from "actions/shiftsActions";
import { plansService } from "services";
import { getFirstLastInterval } from "utils/datetime";

class ShiftSelector extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			submitted: false,
			errors: {},
			value: "",
			suggestions: [],
			selected_shift: null,
		};

		this.handleChange = this.handleChange.bind(this);
		this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
		this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
		this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
	}

	componentDidMount() {
		const { shifts, getAllShifts } = this.props;
		if (!shifts || shifts.length === 0) {
			getAllShifts();
		}
	}

	handleChange(event, { newValue }) {
		this.setState({ value: newValue });
	}

	onSuggestionsFetchRequested({ value }) {
		const input = value.trim().toLowerCase();
		const suggestions = (this.props.shifts || []).filter((shift) =>
			shift.name.toLowerCase().includes(input)
		);
		this.setState({ suggestions });
	}

	onSuggestionsClearRequested() {
		this.setState({ suggestions: [] });
	}

	getSuggestionValue(suggestion) {
		return suggestion.name;
	}

	renderSuggestion(suggestion) {
		return (
			<div>
				<strong>{suggestion.name}</strong>{" "}
				{getFirstLastInterval(suggestion.time_intervals)}
			</div>
		);
	}

	onSuggestionSelected(event, { suggestion }) {
		// Detener la propagación para que no se active el onClick de la celda
		event.stopPropagation();

		// Guarda el turno seleccionado y procede a asignarlo
		this.setState({ selected_shift: suggestion }, () => this.saveShiftPlan());
	}

	showErrors = (response) => {
		if (response?.errors?.length) {
			response.errors.forEach((error) => {
				toastr.error("", `${error.description}`);
			});
		}
	};

	async saveShiftPlan() {
		const { selected_shift } = this.state;

		if (!selected_shift) {
			toastr.error("Error", "Debes seleccionar un turno");
			return;
		}
		this.setState({ isLoading: true });
		const { plan, handleClose, updateEmployeeIdsAndGetAllAssignments, onAdd } = this.props;
		const employee = plan.employee;
		const funciones = employee.employee_with_function ? employee.employee_with_function.function.id : -1;
		const ubicaciones = employee.employee_with_ubication ? employee.employee_with_ubication.ubication.id : -1;

		const newPlan = {
			day: plan.day,
			employee_id: employee.id,
			shift_id: selected_shift.id,
			locked: true,
			ubication_ids: [],
		};

		if (funciones > 0)
			newPlan.function_id = funciones;

		if (ubicaciones > 0)
			newPlan.ubication_ids.push(ubicaciones * 1);

		const response = await plansService.add(newPlan);

		if (response.ok) {
			this.setState({
				isLoading: false,
				errors: {},
			}, () => {
				toastr.success('¡Bien!', 'Turno asignado correctamente');
				handleClose();

				if (updateEmployeeIdsAndGetAllAssignments) {
					// console.log("updateEmployeeIdsAndGetAllAssignments");
					const update_employee_ids = [employee.id];
					updateEmployeeIdsAndGetAllAssignments(update_employee_ids);
				}
				if (onAdd) {
					// console.log("onAdd");
					const update_employee_ids = [employee.id];
					onAdd(update_employee_ids);
				}

			});
		}
		else {
			this.setState({
				isLoading: false,
				submitted: true,
				// errors: errorFormating(response)
			}, () => {
				// const errorMsg = errorFormating(response);
				this.showErrors(response);
				handleClose();
			});
		}
	}

	render() {
		const { value, suggestions, isLoading } = this.state;

		return (
			<div className="shift-selector-content">
				<form>
					<div className="shift-selector-popover-body">
						<FormGroup className="form-search">
							<Autosuggest
								suggestions={suggestions}
								onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
								onSuggestionsClearRequested={this.onSuggestionsClearRequested}
								getSuggestionValue={this.getSuggestionValue.bind(this)}
								renderSuggestion={this.renderSuggestion.bind(this)}
								inputProps={{
									placeholder: "Buscar...",
									className: "form-control",
									value: value,
									onChange: this.handleChange,
									onFocus: (e) => {
										e.stopPropagation(); // Evita que el foco se propague y active la selección de la celda
										// Al hacer focus, forzamos a buscar sugerencias con valor vacío
										this.onSuggestionsFetchRequested({ value: "" });
									},
									onFocusCapture: (e) => {
										e.stopPropagation();
									},
									onMouseDown: (e) => {
										e.stopPropagation();
									},
									onClick: (e) => {
										e.stopPropagation();
									}
								}}
								alwaysRenderSuggestions={true}
								onSuggestionSelected={this.onSuggestionSelected}
							/>
							<FiSearch />
						</FormGroup>
					</div>
				</form>
			</div>
		);
	}
}

const mapStateToProps = (reducers) => ({
	...reducers.shiftsReducer,
	loading: reducers.shiftsReducer.loading,
});

const mapDispatchToProps = (dispatch) => ({
	getAllShifts: (options) => dispatch(shiftsActions.getAllShifts(options)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShiftSelector);
