import React, { Fragment, useState } from "react";
import moment from 'moment';
import { FaLock, FaComment } from "react-icons/fa";
import { RiPencilFill } from "react-icons/ri";
import { ImClock } from "react-icons/im";
import { GoAlert } from "react-icons/go";
import { OverlayTrigger, Tooltip, } from "react-bootstrap";
import { ContextMenuTrigger } from "react-contextmenu";
// import ShiftPreviewTooltip from 'components/Shifts/ShiftPreviewTooltip';
import PlanShiftHoverMenu from "components/Plans/PlanShiftHoverMenu";
const MENU_SHIFT_ID = "menu_shift";
const MENU_DAY_ID = "menu_day";
import { getContrastColor } from "utils/utils";


function collect(props) {
	return props;
}


const getRowContent = (props, hoveredAssignmentId, setHoveredAssignmentId, isDragging, setIsDragging, dragOverCell, setDragOverCell) => props.plan_days.map((d, i) => {
	// console.log(props);
	const key = `${d.day}_${i}`;

	return (
		<tr key={key}>
			{getDayHeader(props, d, i)}
			{getPlanRowContent(props, hoveredAssignmentId, setHoveredAssignmentId, isDragging, setIsDragging, dragOverCell, setDragOverCell, d, i)}
		</tr>
	)
});

const getDayHeader = (props, d, i) => {
	const day = moment(d.day).format("ddd");
	const dayNum = moment(d.day).format("DD/MM");
	const details = d.details;

	// console.log(d);
	let day_type;
	const isToday = moment(d.day).isSame(moment(), 'day');

	// TODO adaptar multigrup
	if (details.length > 0) {
		if (details[0].special_day)
			day_type = details[0].special_day.day_type;

		if (details[0].weekday)
			day_type = details[0].weekday.day_type;
	}

	const color = day_type.color;
	const key = `th_${day}`;
	return (
		<th
			className={`th-day th-day-extended th-selectable  ${isToday ? "today" : ""}`}
			style={{ color: color }}
			key={key}
			onClick={() => props.handleColumnSelection(i)}
		>
			<div className="th-day-content">
				<span className="span-day">{day}</span>
				<span className="span-num">{dayNum}</span>
			</div>
		</th>
	)
};

// contenido celdas turnos
const getPlanRowContent = (props, hoveredAssignmentId, setHoveredAssignmentId, isDragging, setIsDragging, dragOverCell, setDragOverCell, day, row_inv) => props.plan_assignments.map((plan_assignment, i_inv) => {

	const employee = plan_assignment.employee;
	const item = plan_assignment.plans.find(item => item.day === day.day);
	const checked = item ? item.checked : false;

	// fix para invertir filas y columnas
	const row = i_inv;
	const i = row_inv;

	const cut = props.day_selection_copy.length > 0 && props.day_selection_copy.find(item => item.row === row && item.index === i);
	const dashed = props.day_selection_dashed.length > 0 && props.day_selection_dashed.find(item => item.row === row && item.index === i);
	// const cut = props.day_selection_copy.length > 0;
	const key = `td_${day.day}_${row}_${i}`;

	if (item.active) {
		if (item.assignments.length === 0) {
			item.assignments = [{ id: -1, shift: props.default_shift }];
		}

		return (
			<td
				key={key}
				className={`shift-cell shift-cell-intervals btn-checkbox 
					${checked ? "active" : ""} 
					${cut ? "cut" : ""} 
					${dashed ? "dashed" : ""}
					${dragOverCell === `${row}-${i}` ? "drag-over" : ""}
					`}

				onDragOver={(e) => onDragOver(e, row, i, setDragOverCell)}
				onDrop={(e) => onShiftsDrop(e, props, row, i, setIsDragging, setDragOverCell)}
				data={{ action: "paste-shift" }}
			>
				<ContextMenuTrigger
					id={MENU_DAY_ID}
					holdToDisplay={1000}
					collect={collect}
					hasShifts={item.shifts ? true : false}
					onItemClick={props.handleDayMenuClick}
				>
					<input
						type="checkbox"
						id={`day_${row}_${i}`}
						onChange={(e) =>
							props.hasPrivileges && props.handleDaySelection(row, i, e)
						}
						checked={checked}
					/>
					<label
						htmlFor={`day_${row}_${i}`}
						className="btn-check-label"
						onContextMenu={() => props.selectSingleDay(row, i)}
						draggable
						onDragEnter={(e) => props.selectSingleDay(row, i)}
					></label>

					<ul className="shift-list-items">
						{getAssignedShifts(props, hoveredAssignmentId, setHoveredAssignmentId, isDragging, setIsDragging, employee, item, row, i)}
					</ul>
				</ContextMenuTrigger>
			</td>
		);
	} else {
		return <td key={i} className="shift-cell disabled"></td>;
	}
});


const getAssignedShifts = (props, hoveredAssignmentId, setHoveredAssignmentId, isDragging, setIsDragging, employee, sp_item, row, index) =>
	sp_item.assignments.map((item) => {
		const shift = item.shift;

		// time intervals editados
		// const editedTimes = item.time_intervals && item.time_intervals.find(item => item.time_type.work_time === false);
		const key = item.id === -1 ? `empty-${row}-${index}` : item.id; // Usamos el id de la asignación como key
		const editedTimes = item.time_intervals_from_manual && item.time_intervals && item.time_intervals.length > 0;

		const day_selection = props.day_selection;
		const plan = {
			employee: employee,
			day: sp_item.day,
			shift_plan: item,
		};

		const shiftname = shift.description ? shift.name + " - " + shift.description : shift.name;

		const tooltip_alerta = <Tooltip className="tooltip-alerta">
			<ul>
				{item.alert_min_rest_hours && (<li><GoAlert className="tooltip-icon icon-alert" /> No se cumple el mínimo de horas entre turnos</li>)}
				{item.alert_max_consecutive_working_days && (<li><GoAlert className="tooltip-icon icon-alert" /> No se cumple el máximo de días de trabajo consecutivos</li>)}
			</ul>
		</Tooltip>;

		// const tooltip_turno = <Tooltip className="tooltip-shift"><ShiftPreviewTooltip shift={shift} time_intervals={shift.time_intervals} /></Tooltip>;


		// console.log(sp_item.day);
		// console.log(item);
		return (
			// <OverlayTrigger overlay={tooltip_turno}>
			<li
				key={shift.id}
				title={`${shiftname}`}
				className="shift-item"
				style={{
					backgroundColor: shift.background_color && shift.background_color,
					borderColor: shift.border_color && shift.border_color,
					color: getContrastColor(shift.background_color),
					cursor: "grab",
				}}
				draggable
				onDragStart={(e) => {
					setIsDragging(true);
					onShiftDragStart(e, plan);
				}}
				onDragEnd={() => setIsDragging(false)}
				onClick={(e) =>
					props.hasPrivileges && props.handleDaySelection(row, index, e)
				}
				onMouseEnter={() => {
					if (!isDragging && props.hasPrivileges) {
						setTimeout(() => setHoveredAssignmentId(key), 5);
						//setHoveredAssignmentId(key)
					}
				}}
				onMouseLeave={() => {
					if (!isDragging && props.hasPrivileges && !props.isAddingShift) {
						// setTimeout(() => setHoveredAssignmentId(null), 5);
						setHoveredAssignmentId(null);
					}
				}}
			>
				<ContextMenuTrigger
					id={day_selection.length > 0 ? MENU_DAY_ID : MENU_SHIFT_ID}
					holdToDisplay={1000}
					collect={collect}
					onItemClick={
						day_selection.length > 0
							? props.handleDayMenuClick
							: props.handleShiftMenuClick
					}
					targetId={item.id}
					plan={plan}
				>
					{shift.id !== props.default_shift.id
						? shift.name
						: "-"}

					{item.locked && <FaLock className="plan-icon icon-locked" />}
					{item.time_intervals_from_override_time_registrations && (
						<ImClock className="plan-icon icon-clock" />
					)}
					{item.comments && (
						<FaComment className="plan-icon icon-comments" />
					)}
					{(item.alert_min_rest_hours || item.alert_max_consecutive_working_days) && (
						<OverlayTrigger overlay={tooltip_alerta}>
							<GoAlert className="plan-icon icon-alert" />
						</OverlayTrigger>
					)}
					{editedTimes && <RiPencilFill className="plan-icon icon-edited" />}
				</ContextMenuTrigger>

				{/* Renderizamos el menú de hover solo si este assignment está en hover */}
				{hoveredAssignmentId === key && (
					<PlanShiftHoverMenu
						plan={plan} // Se pasa el contexto completo (empleado, día y asignación)
						onAdd={(update_employee_ids) => props.updateEmployeeIdsAndGetAllAssignments(update_employee_ids)}
						onEdit={() => props.handleEditShift(plan)}
						onDelete={() => props.handleRemoveShift(plan, plan.shift_plan.id)}
					/>
				)}
			</li>
			// </OverlayTrigger>
		);
	});



const onDragOver = (e, row, index, setDragOverCell) => {
	e.preventDefault(); // Necesario para permitir el drop

	const cellKey = `${row}-${index}`;
	setDragOverCell(cellKey);
};

const onShiftsDrop = (e, props, row, i, setIsDragging = () => { }, setDragOverCell = () => { }) => {
	e.preventDefault();
	e.stopPropagation();
	setIsDragging(false);
	setDragOverCell(null);
	const shiftData = e.dataTransfer.getData("shift");

	if (shiftData) {
		const shiftPlan = JSON.parse(shiftData);
		props.handleMoveShift(shiftPlan, row, i);
	} else {
		props.selectSingleDay(row, i);
		props.handleDayMenuClick(e, { action: "paste-shift" });
	}
};

const onShiftDragStart = (e, plan) => {
	e.dataTransfer.setData("shift", JSON.stringify(plan));
	e.dataTransfer.effectAllowed = "move";
};

const PlanTableBodyInverted = (props) => {
	//console.log(props);
	const [hoveredAssignmentId, setHoveredAssignmentId] = useState(null);
	const [isDragging, setIsDragging] = useState(false);
	const [dragOverCell, setDragOverCell] = useState(null);

	return (
		<tbody>
			{getRowContent(props, hoveredAssignmentId, setHoveredAssignmentId, isDragging, setIsDragging, dragOverCell, setDragOverCell)}
		</tbody>
	)
}

export default PlanTableBodyInverted